import { FC, useState } from 'react'
import { Avatar, MenuItem, IconButton, Tooltip, Menu, ListItemIcon, ListItemText } from '@mui/material'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import HomeIcon from '@mui/icons-material/Home'
import LogoutIcon from '@mui/icons-material/Logout'
import { RoutePaths } from '@/navigation/route'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useAuth from '@/context/useAuth'
import { formatInitials } from './formatInitials'

interface AvatarMenuProps {
    size?: number
}

const AvatarMenu: FC<AvatarMenuProps> = ({ size }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { me, handleSignOut } = useAuth()
    if (!me) {
        return null
    }
    return (
        <>
            <Tooltip title="Account settings">
                <IconButton onClick={handleOpen}>
                    <Avatar sx={{ width: size, height: size }}>{formatInitials(me)}</Avatar>
                </IconButton>
            </Tooltip>
            <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
                <MenuItem
                    onClick={() => {
                        navigate(RoutePaths.Settings)
                        handleClose()
                    }}
                >
                    <ListItemIcon>
                        <AccountBoxIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={t('auth.accountSettings')} />
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        navigate('/')
                        handleClose()
                    }}
                >
                    <ListItemIcon>
                        <HomeIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={t('auth.mySites')} />
                </MenuItem>
                <MenuItem onClick={handleSignOut}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={t('auth.logout')} />
                </MenuItem>
            </Menu>
        </>
    )
}

export default AvatarMenu
