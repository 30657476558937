import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import common from './fr/common.json'
import home from './fr/home.json'
import connect from './fr/connect.json'
import site from './fr/site.json'
import fallback from './fr/fallback.json'

// from https://github.com/i18next/react-i18next/tree/master/example/react-typescript/simple-multi-namespaces

export const defaultNS = 'common'

i18next.use(initReactI18next).init({
    lng: 'fr', // if you're using a language detector, do not define the lng option
    debug: true,
    resources: {
        fr: {
            common,
            connect,
            home,
            site,
            fallback,
        },
    },
    defaultNS,
})
