import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import '../../assets/css/calendar.css'
import { useSelector, useDispatch } from 'react-redux'
import CalendarSlice from '../../store/slices/calendar.slice'
import moment from 'moment'
import { event } from '../../utils/ga.utils'
import { getSiteNotes } from '@/services/site.service'

import { store } from '../../store/configure.store'
import GlobalSlice from '../../store/slices/global.slice'

const CalendarComponent = (props) => {
    const calendarStore = useSelector((state) => state.calendarStore)
    const [start, setStart] = useState(calendarStore.start)
    const [end, setEnd] = useState(calendarStore.end)
    const dispatch = useDispatch()
    const { selectedSite } = useSelector((state) => state.globalStore)

    const [notes, setNotes] = useState([])

    useEffect(() => {
        init()
    }, [selectedSite])

    const init = async () => {
        let res = await getSiteNotes(selectedSite?.Hash)
        if (res?.notes) {
            if (selectedSite?.notes?.length !== res?.notes?.length) {
                store.dispatch(GlobalSlice.actions.setSelectedSite({ ...selectedSite, notes: res?.notes }))
            }

            setNotes(res?.notes)
        }
    }

    React.useEffect(() => {
        if (calendarStore.start) setStart(calendarStore.start)
        if (calendarStore.end) setEnd(calendarStore.end)
    }, [calendarStore])

    // Define the specific date(s) for the emoji

    // Define tile content to add an emoji and a hover message to the specific date
    const tileContent = ({ date, view }) => {
        // Add emoji and tooltip only for "month" view and on specific date(s)

        let find = notes.find((note) => moment(note?.Date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'))


        if (view === 'month' && find) {
            return (
                <div title={find?.Title} style={{ position: 'relative' }}>
                    <span role="img" aria-label="special">
                        🗒️
                    </span>{' '}
                    {/* Emoji */}
                </div>
            )
        }
        return null
    }

    return (
        <Calendar
            className="agrid-calendar"
            onChange={(data) => {
                event('Calendar', `Changed dates: ${data[0].toString()} / ${data[1].toString()}`)
                let momentStart = moment(data[0]).toDate().toString()
                let momentEnd = moment(data[1]).toDate().toString()
                dispatch(
                    CalendarSlice.actions.updateCalendar({
                        start: momentStart,
                        end: momentEnd,
                    })
                )
                props.onChange({ start: momentStart, end: momentEnd })
            }}
            onClickMonth={(value) => {
                let momentStart = moment(value).startOf('month').toDate().toString()
                let momentEnd = moment(value).endOf('month').toDate().toString()
                dispatch(
                    CalendarSlice.actions.updateCalendar({
                        start: momentStart,
                        end: momentEnd,
                    })
                )
                props.onChange({ start: momentStart, end: momentEnd })
            }}
            key={`${new Date(start)}-${new Date(end)}`}
            returnValue={'range'}
            value={[new Date(start), new Date(end)]}
            selectRange={true}
            defaultView="month"
            next2Label={null}
            prev2Label={null}
            maxDate={new Date()}
            formatShortWeekday={(locale, value) => ['D', 'L', 'M', 'M', 'J', 'V', 'S'][value.getDay()]}
            tileContent={tileContent} // Add tileContent to render emojis and hover message
        />
    )
}

export default CalendarComponent
