import { VFC } from 'react'
import {
    Dialog,
    DialogProps,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Alert,
    CircularProgress,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

const SendLoader: VFC<DialogProps> = (props) => {
    const { t } = useTranslation('site')
    return (
        <Dialog {...props}>
            <DialogContent>
                <DialogTitle
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <CircularProgress />
                </DialogTitle>
                <DialogContentText
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Alert severity="warning" style={{ marginBottom: 20 }}>
                        {t('realtime.sendLoader.description')}
                    </Alert>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default SendLoader
