import { VFC } from 'react'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'
import { NavCalendarComponent } from '@/components/Global/nav-calendar.component'
import SiteDashboardFallback from './fallback'
import SiteHeader from '../siteHeader.component'

const SiteDashboardLayout: VFC = () => {
    const { t } = useTranslation('site')

    return (
        <Box>
            <SiteHeader title={t('navLinks.dashboard')} />
            <Grid
                item
                container
                xs={12}
                justifyContent="flex-start"
                alignItems="baseline"
                sx={({ palette }) => ({ mb: 2, pb: 1, borderBottom: `1px solid ${palette.grey[300]}`, gap: 1 })}
            >
                <NavCalendarComponent />
            </Grid>
            <ErrorBoundary fallbackRender={SiteDashboardFallback}>
                <Outlet />
            </ErrorBoundary>
        </Box>
    )
}

export default SiteDashboardLayout
