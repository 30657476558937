import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsData from 'highcharts/modules/data'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsHeatmap from 'highcharts/modules/heatmap'
import HighchartsTreeChart from 'highcharts/modules/treemap'
import React, { useEffect, useMemo, useState } from 'react'

// import "./styles.css";
import { Card, CardContent } from '@mui/material'
import { CardTitle } from './card-title.component'

HighchartsData(Highcharts)
HighchartsHeatmap(Highcharts)
HighchartsTreeChart(Highcharts)
HighchartsExporting(Highcharts)

const createChartOptions = (points, onclick) => ({
    series: [
        {
            type: 'treemap',
            layoutAlgorithm: 'squarified',
            allowDrillToNode: true,
            animation: false,
            dataLabels: {
                enabled: true,
            },
            levelIsConstant: false,
            levels: [
                {
                    level: 1,
                    layoutAlgorithm: 'squarified',
                    dataLabels: {
                        enabled: true,
                        align: 'left',
                        verticalAlign: 'top',
                        style: {
                            fontSize: '15px',
                            fontWeight: 'bold',
                            fontFamily: 'arial',
                            textOutline: 'none',
                        },
                    },
                },
            ],
            data: points,
            cursor: 'pointer',
            point: {
                events: {
                    click: function () {
                        // Define your click action here
                        // 'this' refers to the point that was clicked
                        console.log('Point clicked:', this)
                        onclick(this.id)
                        // You can trigger any action here, like setting state or calling a function
                    },
                    // click: (e) => {
                    //   e.preventDefault();
                    //   onclick(e);
                    // },
                },
            },
        },
    ],
    title: false,
    credits: false,
    tooltip: {
        useHTML: true,
        pointFormat: '<b>{point.name}</b>: <b>{point.value}%',
    },
})

const getTotal = (_data) => {
    let total = 0
    for (const [key, value] of Object.entries(_data)) {
        total += value?.Consumption
    }
    return total
}

export const TreeComponent = ({ data, siteHash, setSelectedZone }) => {
    // data = { site: data }
    const [points, setPoints] = useState([])
    const chartOptions = useMemo(
        () =>
            createChartOptions(points, (id) => {
                setSelectedZone(id)
            }),
        [points]
    )
    useEffect(() => {
        init()
    }, [siteHash])

    const init = () => {
        let points = []
        let total = getTotal(data)
        let count = 0
        for (const key in data) {
            count += 1
            let obj = data[key]
            let value = Math.round((obj.Consumption * 100) / total)
            // compute opacity based on the value
            let opacity = Math.min(0.1 + (value / 3) * 0.9, 1)
            points.push({
                id: obj['ObjectHash'],
                name: obj['ObjectName'],
                value: value,
                color: `rgba(45, 202, 213, ${opacity})`, //Highcharts.getOptions().colors[count % Highcharts.getOptions().colors?.length]
            })
        }
        setPoints(points)
    }

    return (
        <Card key={siteHash}>
            <CardContent>
                <CardTitle title={'Répartition de la consommation'} />
                <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            </CardContent>
        </Card>
    )
}
