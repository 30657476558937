import { Grid } from '@mui/material'
import React from 'react'
import AlternativeRow from '../../realTime/components/alternative-row.component'
import ConstraintModal from './constraint.modal'
import { GroupComponent } from './groups.component'
import { useNavigate } from 'react-router-dom'

export const Tab2Component = ({
    saveObject,
    constraints,
    setModified,
    rawObjects,
    selectedObject,
    setObjectUpdates,
    changeSpaceType,
    setRawObjects,
    currentGroup,
    updateCurrentGroup,
    objectUpdates,
    groups,
    removeGroup,
    setGroupDialog,
    updateGroup,
    groupDialog,
    setCurrentGroup,
    SaveMenu,
    modal,
    objectNewName,
    setObjectNewName,
}) => {
    const navigate = useNavigate()

    if (modal && constraints)
        return (
            <ConstraintModal
                save={saveObject}
                constraints={constraints}
                setModified={setModified}
                SaveMenu={SaveMenu}
                setObjectNewName={setObjectNewName}
                objectNewName={objectNewName}
            />
        )
    else if (rawObjects?.length > 0 && !selectedObject)
        return (
            <>
                <Grid container spacing={2} style={{ marginTop: 20 }}>
                    <Grid
                        item
                        md={9}
                        id={'list'}
                        //   style={{ height: `calc(100% - 500px)`, overflow: "scroll" }}
                    >
                        <AlternativeRow
                            mainEditable={true}
                            show={(row) => {
                                navigate(`${row.ObjectId}`)
                            }}
                            rawObjects={rawObjects?.filter((el) => el?.ObjectTypeId !== 3 && el?.Name)}
                            draggable={true}
                            updateObject={async (objectId, objectType) => {
                                let find = objectUpdates.find((el) => el.objectId === objectId)
                                if (!find) setObjectUpdates([...objectUpdates, { objectId, objectType }])
                                else {
                                    let newUpdates = objectUpdates.map((el) => {
                                        if (el.objectId === objectId) return { ...el, objectType }
                                        else return el
                                    })
                                    setObjectUpdates(newUpdates)
                                }
                                changeSpaceType(objectId, objectType)
                                let newObjects = rawObjects.map((el) => {
                                    if (el.ObjectId === objectId) return { ...el, SpaceType: objectType }
                                    else return el
                                })
                                setModified(true)
                                setRawObjects(newObjects)
                            }}
                            updateCurrentGroup={updateCurrentGroup}
                            currentGroup={currentGroup}
                        />
                    </Grid>

                    <Grid item md={3} style={{ position: 'relative' }}>
                        <GroupComponent
                            currentGroup={currentGroup}
                            groupDialog={groupDialog}
                            removeGroup={removeGroup}
                            groups={groups}
                            setCurrentGroup={setCurrentGroup}
                            setGroupDialog={setGroupDialog}
                            updateGroup={updateGroup}
                            rawObjects={rawObjects}
                            updateCurrentGroup={updateCurrentGroup}
                        />
                    </Grid>
                </Grid>
            </>
        )
    else return null
}
