import React, { FC } from 'react'
import { Box, Drawer, DrawerProps, Toolbar, useTheme } from '@mui/material'
import { useMatch } from 'react-router-dom'
import { DRAWER_WIDTH } from './constants'
import Logo from '@/assets/img/brand/logo.png'
import SiteNavLinks from '@/views/site/NavLinks'
import { RoutePaths } from '@/navigation/route'

export const useEnableNavDrawer = () => {
    const match = useMatch(`${RoutePaths.Sites}/*`)
    return Boolean(match)
}

const NavDrawer: FC<Omit<DrawerProps, 'sx'>> = (props) => {
    const { palette } = useTheme()
    const enable = useEnableNavDrawer()
    if (!enable) {
        return null
    }

    return (
        <Drawer
            {...props}
            sx={{
                width: DRAWER_WIDTH,

                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: DRAWER_WIDTH,
                    backgroundColor: palette.background.default,
                },
                zIndex: (theme) => theme.zIndex.appBar - 1,
            }}
            component="nav"
        >
            <Toolbar />

            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
                <SiteNavLinks />
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                    <img src={Logo} alt="logo" style={{ width: '65%' }} />
                </Box>
            </Box>
        </Drawer>
    )
}

export default NavDrawer
