import { Checkbox, FormControlLabel } from '@mui/material'
import HighchartsChart from '@/components/Charts/HighchartsChart'
import React from 'react'
import { lightGrey } from '@/components/Basic/colors'

export const LineChartComponent = ({
    series,
    toggle,
    toggleSeries,
    yAxis,
    xAxis,
    exporting,
    plotOptions,
    events,
    type,
    zoomBySingleTouch,
    tooltip,
}) => {
    return (
        <div>
            {toggle ? (
                <div style={{ display: 'flex', flexWrap: 'wrap', minHeight: 40 }}>
                    {series?.map((el) => {
                        return (
                            <div style={{ height: 30 }} key={el?.hash}>
                                {' '}
                                <FormControlLabel
                                    labelPlacement="start"
                                    control={<Checkbox defaultChecked />}
                                    label={
                                        <span
                                            style={{
                                                fontSize: 12,
                                                color: el?.visible ? '' : lightGrey,
                                            }}
                                        >
                                            {el.name}
                                        </span>
                                    }
                                    onChange={() => toggleSeries(el?.hash)}
                                />
                            </div>
                        )
                    })}
                </div>
            ) : null}
            <HighchartsChart
                options={{
                    chart: {
                        type: type || 'column',
                        events: events || {},
                        zoomBySingleTouch: zoomBySingleTouch !== undefined ? zoomBySingleTouch : false,
                        zoomType: zoomBySingleTouch !== undefined ? 'x' : null,
                    },
                    legend: { enabled: false },
                    title: {
                        text: '',
                        enabled: false,
                    },
                    yAxis: yAxis || [{}],
                    xAxis: xAxis || [{}],
                    series: series,
                    exporting: {
                        enabled: exporting !== undefined ? exporting : true,
                    },
                    plotOptions,
                    tooltip: tooltip || {},
                }}
            />
        </div>
    )
}
