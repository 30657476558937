import React, { useEffect, useState } from 'react'

import Logo from '@/assets/img/brand/logo.png'
import { QRCodeCanvas } from 'qrcode.react'
import { getObjectsForSite } from '@/services/realtime.service'
import { isDemo } from '@/services/utils.service'
import { useLocation } from 'react-router-dom'
let nb = 16

export const PdfPage = () => {
    const [objects, setObjects] = useState([])
    const [pages, setPages] = useState([])
    const useQuery = () => {
        return new URLSearchParams(useLocation().search)
    }
    let query = useQuery()

    useEffect(() => {
        fetchObjects()
    }, [])

    const fetchObjects = async () => {
        let hash = query.get('hash')
        setObjects([])
        const res = await getObjectsForSite({ hash: hash })
        if (res?.objects) {
            let newObjects = isDemo()
                ? res?.objects
                : res?.objects?.filter((el) => el?.ObjectTypeId !== 1 && el?.ObjectTypeId !== 3 && el?.ObjectHash)
            let newPages = [[], []]
            let count = 0
            for (let i = 0; i < newObjects.length; i++) {
                if (i % nb === 0 && i !== 0) {
                    newPages.push([], [])
                    count++
                }
                newPages[count * 2].push(newObjects[i])
                newPages[count * 2 + 1].push({
                    label: true,
                    name: newObjects[i]?.Name,
                })
            }
            for (let i = 0; i < newPages.length; i++) {
                if (i % 2 === 1) {
                    let _tmp = newPages[i]
                    for (let j = 0; j < newPages[i]?.length; j += 2) {
                        _tmp = swap(_tmp, j, j + 1)
                    }
                    console.log('tmp', _tmp)
                    newPages[i] = _tmp
                }
            }

            setPages(newPages)

            //   setObjects([...newObjects]);
        }

        console.log('res', res)
    }

    // function that exchange the place of two elements in an array
    const swap = (arr, indexA, indexB) => {
        const temp = arr[indexA]
        arr[indexA] = arr[indexB] || { label: true }
        arr[indexB] = temp || { label: true }
        return arr
    }

    return (
        <>
            {pages?.map((page, index) => (
                <>
                    <div
                        style={{
                            padding: 10,
                            width: '210mm',
                            height: '297mm',
                            paddingTop: 10,
                            color: 'black',
                            boxSizing: 'border-box',
                            background: 'white',
                            position: 'relative',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                            }}
                        >
                            {page?.map((el) => {
                                if (el.label) {
                                    if (!el.name) return null
                                    return (
                                        <div
                                            key={el.name}
                                            style={{
                                                width: '45%',
                                                marginTop: 20,

                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height: 90,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    // border: "1px dashed grey",
                                                    padding: 4,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {el.name}
                                            </div>
                                        </div>
                                    )
                                } else
                                    return (
                                        <Etiquette
                                            idx={0}
                                            nb={30}
                                            style={{}}
                                            hash={query.get('hash')}
                                            objectHash={el?.ObjectHash}
                                        />
                                    )
                            })}
                        </div>
                    </div>
                    <br />
                </>
            ))}
        </>
    )
}

const Etiquette = ({ language, qr, idx, styles, nb, hash, objectHash }) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid black',
                padding: 6,
                width: '45%',
                position: 'relative',
                marginTop: 20,
                height: 90,
            }}
        >
            <QRCodeCanvas
                value={`https://api.a-grid.com/api/client-app/scan?hash=${hash}&objectHash=${objectHash}`}
                id={`qrcode_${objectHash}`}
                key={`qrcode_${objectHash}`}
                fgColor={'#000000'}
                style={{ width: '70px', height: '70px' }}
            />

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '2mm',
                    fontSize: 12,
                }}
            >
                <div>
                    {language === 'english'
                        ? 'We optimize the energy of your space, '
                        : " Nous optimisons l'energie de votre espace, ​"}
                    {language === 'english'
                        ? 'scan this QR Code to learn more'
                        : 'scannez ce QR Code pour en savoir plus.​'}
                </div>
            </div>
            <div style={{ position: 'absolute', right: '1mm', top: '1mm' }}>
                <img src={Logo} style={{ width: '10mm' }} />
            </div>
        </div>
    )
}
