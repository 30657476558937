export function firstNameInitials(firstName: string | null): string | null {
    if (!firstName) {
        return null
    }
    return firstName
        .replace(/^[^\w]*/g, '')
        .split(/[\s-]+/)
        .map((part) => part.charAt(0))
        .join('')
        .toUpperCase()
}

export function lastNameInitials(lastName: string | null): string | null {
    if (!lastName) {
        return null
    }
    return lastName
        .replace(/^[^\w]*/g, '')
        .split(/[\s-]+/)
        .sort((a, b) => b.length - a.length)[0]
        .charAt(0)
        .toUpperCase()
}

export function formatInitials(user: { firstName: string | null; lastName: string | null }): string | null {
    const initials = [firstNameInitials(user.firstName), lastNameInitials(user.lastName)].filter(Boolean).join('')
    return initials.length > 0 ? initials : null
}
