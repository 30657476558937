import React from 'react'

import { Card, CardContent, Chip, Typography } from '@mui/material'
import { linkBlue } from '@/components/Basic/colors'
import { isDemo } from '@/services/utils.service'

const TemperatureInput = ({ min, max, onChange, value, label, disabled, type }) => {
    return (
        <Card>
            <CardContent style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', top: 5, right: 5 }}>
                    {type == 'heat' ? (
                        <Chip label="chaud" style={{ background: '#ff5252', color: 'white' }} size="small" />
                    ) : (
                        <Chip label="froid" style={{ background: linkBlue, color: 'white' }} size="small" />
                    )}
                </div>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {label.toLowerCase()}
                </Typography>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 15,
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <Typography variant="h3" component="div">
                            {value}
                        </Typography>
                        <Typography color="text.secondary" component="div">
                            {'°C'}
                        </Typography>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                        }}
                    >
                        {' '}
                        <div
                            style={{
                                cursor: disabled ? '' : 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                margin: 2,
                            }}
                            onClick={() => {
                                if ((!disabled && value + 1 <= max) || isDemo()) onChange(parseInt(value) + 1)
                            }}
                        >
                            <svg
                                width="22"
                                height="22"
                                viewBox="0 0 14 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clipPath="url(#clip0_444_5288)">
                                    <path
                                        d="M6.99996 14.2083C8.32674 14.2083 9.62373 13.8149 10.7269 13.0778C11.8301 12.3406 12.6899 11.2929 13.1977 10.0672C13.7054 8.84137 13.8382 7.49255 13.5794 6.19126C13.3206 4.88997 12.6816 3.69466 11.7435 2.75649C10.8053 1.81831 9.60998 1.1794 8.30869 0.920558C7.0074 0.661716 5.65858 0.794563 4.43279 1.3023C3.207 1.81004 2.15931 2.66986 1.42218 3.77304C0.685063 4.87622 0.291626 6.17321 0.291626 7.49999C0.293633 9.27854 1.00105 10.9837 2.25867 12.2413C3.51629 13.4989 5.22141 14.2063 6.99996 14.2083V14.2083ZM3.49996 6.91666H6.27079C6.30947 6.91666 6.34656 6.9013 6.37391 6.87395C6.40126 6.8466 6.41663 6.8095 6.41663 6.77083V3.99999C6.41663 3.84528 6.47808 3.69691 6.58748 3.58751C6.69688 3.47812 6.84525 3.41666 6.99996 3.41666C7.15467 3.41666 7.30304 3.47812 7.41244 3.58751C7.52183 3.69691 7.58329 3.84528 7.58329 3.99999V6.77083C7.58329 6.8095 7.59866 6.8466 7.62601 6.87395C7.65335 6.9013 7.69045 6.91666 7.72913 6.91666H10.5C10.6547 6.91666 10.803 6.97812 10.9124 7.08751C11.0218 7.19691 11.0833 7.34528 11.0833 7.49999C11.0833 7.6547 11.0218 7.80308 10.9124 7.91247C10.803 8.02187 10.6547 8.08333 10.5 8.08333H7.72913C7.69045 8.08333 7.65335 8.09869 7.62601 8.12604C7.59866 8.15339 7.58329 8.19048 7.58329 8.22916V11C7.58329 11.1547 7.52183 11.3031 7.41244 11.4125C7.30304 11.5219 7.15467 11.5833 6.99996 11.5833C6.84525 11.5833 6.69688 11.5219 6.58748 11.4125C6.47808 11.3031 6.41663 11.1547 6.41663 11V8.22916C6.41663 8.19048 6.40126 8.15339 6.37391 8.12604C6.34656 8.09869 6.30947 8.08333 6.27079 8.08333H3.49996C3.34525 8.08333 3.19688 8.02187 3.08748 7.91247C2.97808 7.80308 2.91663 7.6547 2.91663 7.49999C2.91663 7.34528 2.97808 7.19691 3.08748 7.08751C3.19688 6.97812 3.34525 6.91666 3.49996 6.91666Z"
                                        fill={disabled ? '#e0e0e0' : '#00E786'}
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_444_5288">
                                        <rect width="14" height="14" fill="white" transform="translate(0 0.5)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div
                            style={{
                                cursor: disabled ? '' : 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                margin: 2,
                            }}
                            onClick={() => {
                                if (!disabled && value - 1 >= min) onChange(parseInt(value) - 1)
                            }}
                        >
                            <svg
                                width="22"
                                height="22"
                                viewBox="0 0 15 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clipPath="url(#clip0_444_5291)">
                                    <path
                                        d="M7.66659 14.2083C8.99337 14.2083 10.2904 13.8149 11.3935 13.0778C12.4967 12.3406 13.3565 11.2929 13.8643 10.0672C14.372 8.84137 14.5049 7.49255 14.246 6.19126C13.9872 4.88997 13.3483 3.69466 12.4101 2.75649C11.4719 1.81831 10.2766 1.1794 8.97532 0.920558C7.67403 0.661716 6.3252 0.794563 5.09942 1.3023C3.87363 1.81004 2.82593 2.66986 2.08881 3.77304C1.35169 4.87622 0.958252 6.17321 0.958252 7.49999C0.960259 9.27854 1.66767 10.9837 2.92529 12.2413C4.18292 13.4989 5.88804 14.2063 7.66659 14.2083V14.2083ZM4.16659 6.91666H11.1666C11.3213 6.91666 11.4697 6.97812 11.5791 7.08751C11.6885 7.19691 11.7499 7.34528 11.7499 7.49999C11.7499 7.6547 11.6885 7.80308 11.5791 7.91247C11.4697 8.02187 11.3213 8.08333 11.1666 8.08333H4.16659C4.01188 8.08333 3.8635 8.02187 3.75411 7.91247C3.64471 7.80308 3.58325 7.6547 3.58325 7.49999C3.58325 7.34528 3.64471 7.19691 3.75411 7.08751C3.8635 6.97812 4.01188 6.91666 4.16659 6.91666Z"
                                        fill={disabled ? '#e0e0e0' : '#00E786'}
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_444_5291">
                                        <rect width="14" height="14" fill="white" transform="translate(0.666626 0.5)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}

export default TemperatureInput
