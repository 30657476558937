/* eslint-disable @typescript-eslint/no-explicit-any */
import { VFC } from 'react'
import DpeLabel from '../../dashboard/components/dpeLabel.component'
import { Alert, Card, Box, Skeleton, Grid, Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useTranslation } from 'react-i18next'
import { useDpe } from '../../dashboard/dataProviders/useDpe'

export const DpeLabelsLoader: VFC = () => (
    <Box pb={4}>
        <Skeleton variant="rectangular" height="32px" width="50%" sx={{ my: 1 }} />
        <Skeleton variant="rectangular" height="32px" width="50%" sx={{ my: 1 }} />
    </Box>
)

const DpeLabels: VFC<{ dpes: any[] }> = ({ dpes }) => {
    const { t } = useTranslation('site', { keyPrefix: 'home.dpe' })
    if (!dpes) return null
    const filteredDpes = dpes.filter((el: any) => Boolean(el.value))
    if (!filteredDpes.length) {
        return (
            <Typography variant="body2" sx={{ my: 1, color: 'text.secondary' }}>
                {t('empty')}
            </Typography>
        )
    }

    return (
        <Box>
            {filteredDpes.map((dpe: any) => (
                <Grid container key={dpe.title} spacing={1} sx={{ my: 1 }} alignItems="center">
                    <Grid item xs={4}>
                        <DpeLabel dpeClass={dpe.class} type={dpe.type} />
                    </Grid>
                    <Grid item container direction="column" xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                            {dpe.title}
                        </Typography>
                        <Typography variant="caption">{`${dpe.value.toFixed(1)} ${dpe.unit}`}</Typography>
                    </Grid>
                </Grid>
            ))}
            <Alert icon={<InfoOutlinedIcon />} severity="info" sx={{ mt: 4 }}>
                {t('disclaimer')}
            </Alert>
        </Box>
    )
}

const DpeLabelsWrapper: VFC<{ siteHash: string }> = ({ siteHash }) => {
    const { isLoading, dpe } = useDpe(siteHash)
    const { t } = useTranslation('site', { keyPrefix: 'home.dpe' })
    return (
        <Card variant="outlined" sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
                {t('title')}
            </Typography>
            {isLoading ? <DpeLabelsLoader /> : <DpeLabels dpes={dpe} />}
        </Card>
    )
}

export default DpeLabelsWrapper
