import { Autocomplete, Card, CardContent, Grid, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Container } from '@mui/system'
import { buttonClick } from '@/services/user.service'
import { questions, tags } from './variables'

export const HelpPageNew = () => {
    const [selectedTag, setSelectedTag] = useState(null)
    const [selectedQuestion, setSelectedQuestion] = useState(null)

    return (
        <Container>
            <BreadCrump
                selectedTag={selectedTag}
                setSelectedTag={() => setSelectedTag(null)}
                selectedQuestion={selectedQuestion}
                setSelectedQuestion={() => setSelectedQuestion(null)}
            />
            {!selectedQuestion && !selectedTag && (
                <SearchBar
                    questions={questions}
                    onClick={(id) => {
                        let find = questions.find((el) => el.id === id)
                        if (find) {
                            setSelectedQuestion(find)
                            let tag = tags.find((el) => el.target === find.tags[0])
                            setSelectedTag(tag)
                            buttonClick({
                                hash: null,
                                button: 'question_' + find.label?.split(' ').join('-').toLowerCase(),
                            })
                        }
                    }}
                />
            )}

            {selectedQuestion ? (
                <>
                    <br />
                    <h3>{selectedQuestion.label}</h3>
                    {selectedQuestion?.video ? (
                        <iframe
                            style={{ border: 0 }}
                            width="800"
                            height="450"
                            src={selectedQuestion?.video}
                            // src="https://www.youtube.com/embed/dQw4w9WgXcQ"
                            allowFullScreen
                        ></iframe>
                    ) : null}

                    {selectedQuestion?.description && (
                        <p dangerouslySetInnerHTML={{ __html: selectedQuestion?.description }} />
                    )}

                    {selectedQuestion?.component && <selectedQuestion.component />}
                </>
            ) : selectedTag ? (
                <>
                    <h2>{selectedTag?.label}</h2>
                    <Grid container spacing={2}>
                        {questions
                            ?.filter((el) => el.tags.includes(selectedTag.target))
                            ?.map((question) => {
                                return (
                                    <Grid item md={12} key={question.id}>
                                        <QuestionCard
                                            question={question}
                                            setSelectedQuestion={() => {
                                                setSelectedQuestion(question)
                                                buttonClick({
                                                    hash: null,
                                                    button:
                                                        'question_' +
                                                        question.label?.split(' ').join('-').toLowerCase(),
                                                })
                                            }}
                                        />
                                    </Grid>
                                )
                            })}
                    </Grid>
                </>
            ) : (
                <div>
                    <h2>Fonctionnalités</h2>
                    <Grid container spacing={2}>
                        {tags
                            ?.filter((tag) => tag?.section == 1)
                            .map((tag, index) => {
                                return (
                                    <Grid item md={3} key={`${tag.target}-${index}`}>
                                        <CardTag
                                            tag={tag}
                                            index={index}
                                            setSelectedTag={() => {
                                                setSelectedTag(tag)
                                                buttonClick({
                                                    hash: null,
                                                    button:
                                                        'questioncategory_' +
                                                        tag.label?.split(' ').join('-').toLowerCase(),
                                                })
                                            }}
                                            questions={questions}
                                        />
                                    </Grid>
                                )
                            })}
                    </Grid>

                    <h2 style={{ marginTop: 30 }}>Utilisateurs</h2>
                    <Grid container spacing={2}>
                        {tags
                            ?.filter((tag) => tag?.section == 2)
                            .map((tag, index) => {
                                return (
                                    <Grid item md={4} key={`${tag.target}-${index}`}>
                                        <CardTag
                                            tag={tag}
                                            index={index}
                                            setSelectedTag={() => {
                                                setSelectedTag(tag)
                                                buttonClick({
                                                    hash: null,
                                                    button:
                                                        'questioncategory_' +
                                                        tag.label?.split(' ').join('-').toLowerCase(),
                                                })
                                            }}
                                            questions={questions}
                                        />
                                    </Grid>
                                )
                            })}
                    </Grid>
                </div>
            )}
        </Container>
    )
}

const SearchBar = ({ questions, onClick }) => {
    return (
        <div style={{ marginBottom: 20 }}>
            <Autocomplete
                disablePortal
                freeSolo
                options={questions}
                sx={{ width: '100%', background: 'white' }}
                renderInput={(params) => <TextField {...params} label="Recherche par mot clé…" id={params?.id} />}
                onChange={(e, v) => {
                    onClick(v?.id)
                    // get the id from the question
                }}
            />
        </div>
    )
}

const CardTag = ({ tag, index, setSelectedTag, questions }) => {
    return (
        <Card style={{ flex: 1, height: '100%' }} key={'tag-' + tag?.label}>
            <CardContent
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}
            >
                <div>
                    <img src={tag?.img} style={{ objectFit: 'cover', width: '100%', marginBottom: 5 }} />
                    <h3>
                        {index + 1}. {tag?.label}
                    </h3>
                    {/** first three questions for the target */}
                    <div>
                        {questions
                            ?.filter((el) => el.tags.includes(tag.target))
                            ?.slice(0, 3)
                            ?.map((question, index) => {
                                return (
                                    <div
                                        key={`question-${question.id}-${index}`}
                                        style={{
                                            fontSize: 13,
                                            lineHeight: '16px',
                                            marginBottom: 4,
                                        }}
                                    >
                                        {question?.emoji} {question?.label}
                                    </div>
                                )
                            })}
                    </div>
                </div>
                <CustomButton
                    onClick={setSelectedTag}
                    content={`${questions?.filter((el) => el.tags.includes(tag.target))?.length} questions`}
                />
                {/* <div
                    style={{
                        border: '1px solid grey',
                        padding: '10px 20px',
                        borderRadius: 4,
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    onClick={setSelectedTag}
                >
                    <div> {questions?.filter((el) => el.tags.includes(tag.target))?.length} questions </div>

                    <div>→</div>
                </div> */}
            </CardContent>
        </Card>
    )
}

const CustomButton = ({ onClick, content }) => {
    return (
        <div
            style={{
                border: '1px solid grey',
                padding: '10px 20px',
                borderRadius: 4,
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
            }}
            onClick={onClick}
        >
            <div> {content}</div>

            <div>→</div>
        </div>
    )
}

const QuestionCard = ({ question, setSelectedQuestion }) => {
    return (
        <Card key={'question-' + question?.label}>
            <CardContent
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}
            >
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <img
                            src={question?.img}
                            style={{ objectFit: 'cover', width: '150px', marginRight: 20, height: '100%' }}
                        />
                    </Grid>

                    <Grid item md={7}>
                        <h3>{question.label}</h3>
                        <p dangerouslySetInnerHTML={{ __html: question?.summary }} />
                    </Grid>
                    <Grid item md={2} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        <CustomButton onClick={setSelectedQuestion} content={`Voir plus`} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

const BreadCrump = ({ selectedTag, setSelectedTag, selectedQuestion, setSelectedQuestion }) => {
    if (selectedTag) {
        return (
            <div style={{ fontSize: 11, display: 'flex' }}>
                <div
                    onClick={() => {
                        setSelectedTag(null)
                        if (selectedQuestion) {
                            setSelectedQuestion(null)
                        }
                    }}
                    style={{ color: 'grey', cursor: 'pointer', marginRight: 2 }}
                >
                    Aide
                </div>
                {' > '}
                <div
                    style={{
                        marginLeft: 3,
                        marginRight: 3,
                        cursor: 'pointer',
                        color: selectedQuestion ? 'grey' : 'black',
                        textDecoration: selectedQuestion ? 'none' : 'underline',
                    }}
                    onClick={() => {
                        if (selectedQuestion) {
                            setSelectedQuestion(null)
                        }
                    }}
                >
                    {selectedTag?.label}
                </div>
                {!selectedQuestion ? null : (
                    <>
                        {' > '}
                        <div style={{ marginLeft: 3, textDecoration: 'underline' }}>
                            {selectedQuestion?.label?.substr(0, 20) + '...'}
                        </div>
                    </>
                )}
            </div>
        )
    }
    return null
}
