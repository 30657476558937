import { VFC } from 'react'
import { Container } from '@mui/material'
import SiteList from './SiteList'
import { Navigate } from 'react-router-dom'
import { RoutePaths } from '@/navigation/route'
import useAuth from '@/context/useAuth'

const Home: VFC = () => {
    const { me } = useAuth()
    const sites = me?.sites
    if (sites?.length === 1) {
        return <Navigate to={`${RoutePaths.Sites}/${sites[0].hash}`} />
    }
    return (
        <Container>
            <SiteList />
        </Container>
    )
}

export default Home
