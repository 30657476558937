import FallbackTemplate from './template.component'
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn'
import { useTranslation } from 'react-i18next'

const Forbidden = () => {
    const { t } = useTranslation('fallback', { keyPrefix: 'forbidden' })
    return (
        <FallbackTemplate
            title={t('title')}
            description={t('description')}
            icon={<DoDisturbOnIcon fontSize="inherit" />}
        />
    )
}

export default Forbidden
