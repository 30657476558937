import DocumentsPage from './documents.page'
import { FC } from 'react'
import SiteHeader from '../siteHeader.component'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'

const SiteDocuments: FC = () => {
    const { t } = useTranslation('site')
    return (
        <Box>
            <SiteHeader title={t('navLinks.documents')} />
            <DocumentsPage />
        </Box>
    )
}

export default SiteDocuments
