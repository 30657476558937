import useSearch from '@/hooks/useSearch'
import useAuth from '@/context/useAuth'
import { fuzzyMatch } from '@/utils/fuzzyMatch'

export const useHome = () => {
    const { search, setSearch, debouncedSearch, handleClearSearch } = useSearch()
    const { me } = useAuth()
    if (!me) {
        throw new Error('User me needs to be defined')
    }
    const { sites: allSites } = me

    const matchedSites = fuzzyMatch(allSites, 'name', debouncedSearch)

    return { search, setSearch, handleClearSearch, allSites, matchedSites }
}
