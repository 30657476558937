import { FC, ReactNode } from 'react'
import { Grid, GridProps, Skeleton } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote'
import ThermostatIcon from '@mui/icons-material/Thermostat'
import { Item } from './item.component'
import { useTranslation } from 'react-i18next'
import { ObjectDataPoint } from './objectDataPoint'
import meanBy from 'lodash/meanBy'

export enum IndicatorKeys {
    ON_PERCENTAGE = 'OnPercentage',
    T_INT = 'TinMean',
    T_SETPOINT = 'SetTempMean',
}

const indicatorIcons: Record<IndicatorKeys, ReactNode> = {
    [IndicatorKeys.ON_PERCENTAGE]: <AccessTimeIcon />,
    [IndicatorKeys.T_SETPOINT]: <ThermostatIcon />,
    [IndicatorKeys.T_INT]: <SettingsRemoteIcon />,
}

const decimalOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
}

const indicatorFormatters: Record<IndicatorKeys, (num: number) => string> = {
    [IndicatorKeys.ON_PERCENTAGE]: (num) =>
        new Intl.NumberFormat('default', { style: 'percent', ...decimalOptions }).format(num),
    [IndicatorKeys.T_SETPOINT]: (num) =>
        new Intl.NumberFormat('default', {
            style: 'unit',
            unit: 'celsius',
            ...decimalOptions,
        }).format(num),
    [IndicatorKeys.T_INT]: (num) =>
        new Intl.NumberFormat('default', {
            style: 'unit',
            unit: 'celsius',
            ...decimalOptions,
        }).format(num),
}

const nanFormatter = (num: number) => (isNaN(num) ? 'N/A' : num.toString())

function getIndicatorAverage(data: ObjectDataPoint[], key: IndicatorKeys) {
    const values = data.filter((item) => typeof item[key] === 'number')
    return meanBy(values, key)
}

export function filterObjectData(data: ObjectDataPoint[], objectHash?: string) {
    if (!objectHash) {
        return []
    }
    return data.filter((point) => point.ObjectHash === objectHash)
}

interface IndicatorProps extends GridProps {
    siteData: ObjectDataPoint[]
    objectHash?: string
}

const Indicators: FC<IndicatorProps> = ({ siteData, objectHash, ...gridProps }) => {
    const { t } = useTranslation('site', { keyPrefix: 'objectDashboard' })
    const objectData = filterObjectData(siteData, objectHash)
    return (
        <Grid container spacing={2} {...gridProps}>
            {(Object.values(IndicatorKeys) as IndicatorKeys[]).map((key) => {
                const objectAverage = getIndicatorAverage(objectData, key)
                const siteAverage = getIndicatorAverage(siteData, key)
                const comparison = objectAverage - siteAverage
                const chipColor = comparison > 0 ? 'green' : 'orange'
                const formattedValue = isNaN(objectAverage)
                    ? nanFormatter(objectAverage)
                    : indicatorFormatters[key](objectAverage)
                return (
                    <Grid item xs={12} md={4} key={key}>
                        <Item
                            number={formattedValue}
                            chipColor={chipColor}
                            unit={t(`indicators.${key}`)}
                            chipValue={(comparison > 0 ? '+' : '') + indicatorFormatters[key](comparison)}
                            icon={indicatorIcons[key]}
                            chip={t('indicators.comparedToSiteAverage')}
                            style={{}}
                            comingSoon={undefined}
                        />
                    </Grid>
                )
            })}
        </Grid>
    )
}

export const IndicatorsLoader: FC = () => (
    <Grid container spacing={2}>
        {(Object.values(IndicatorKeys) as IndicatorKeys[]).map((key) => (
            <Grid item xs={12} md={4} key={key}>
                <Skeleton variant="rectangular" height={120} />
            </Grid>
        ))}
    </Grid>
)

export default Indicators
