export function formatMatches(
    text: string,
    matchedIndexRanges: readonly number[][]
): { text: string; isMatch: boolean }[] {
    if (!matchedIndexRanges.length) {
        return [{ text, isMatch: false }]
    }
    const parts = [{ text: text.slice(0, matchedIndexRanges[0][0]), isMatch: false }]
    matchedIndexRanges.forEach(([start, end], index) => {
        parts.push({ text: text.slice(start, end), isMatch: true })
        parts.push({ text: text.slice(end, matchedIndexRanges[index + 1]?.[0]), isMatch: false })
    })
    return parts.filter(({ text }) => text)
}
