import FallbackTemplate from './template.component'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { useTranslation } from 'react-i18next'

const InternalErrorFallback = () => {
    const { t } = useTranslation('fallback', { keyPrefix: 'error' })
    return (
        <FallbackTemplate
            title={t('title')}
            description={t('description')}
            icon={<ErrorOutlineIcon fontSize="inherit" color="error" />}
        />
    )
}

export default InternalErrorFallback
