import { useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography, List, ListItem, Link } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Medialog1 from '../../../../assets/img/help/pms/medialog_1.png'
import Medialog2 from '../../../../assets/img/help/pms/medialog_2.png'

import MedialogLogo from '../../../../assets/img/help/pms/medialog.png'
import ClockLogo from '../../../../assets/img/help/pms/clock.png'
import MewsLogo from '../../../../assets/img/help/pms/mews.png'
export const PMSQuestion = () => {
    const [expanded, setExpanded] = useState(false)

    const pmsList = [
        {
            name: 'Mews',
            logo: MewsLogo,
            details: (
                <>
                    <Typography>
                        Afin de connecter votre PMS à AGRID, il vous suffit de cliquer sur le lien ci-dessous et de
                        suivre le processus indiqué. Une fois le processus réalisé, Mews et AGRID se mettent en relation
                        pour connecter les deux logiciels, vous n’avez plus rien à effectuer.
                    </Typography>
                    <Link
                        href="https://help.mews.com/s/article/connect-and-disconnect-integrations?language=fr#link1"
                        target="_blank"
                        rel="noopener"
                    >
                        Connecter Mews à AGRID
                    </Link>
                </>
            ),
        },
        {
            name: 'Medialog',
            logo: MedialogLogo,
            details: (
                <>
                    <Typography>
                        Le processus pour connecter Medialog avec AGRID se fait en 2 étapes: <br />
                        <br />
                        <strong>Étape 1</strong> – Vous faites une demande auprès du service commercial Medialog pour
                        établir une connectivité avec AGRID. Medialog vous enverra alors un devis à leur retourner
                        signé.
                        <br />
                        <br />
                        <strong>Étape 2</strong> – Une fois le devis signé et retourné au service commercial Medialog,
                        ils procèderont alors à la mise en place et activation de l’accès API pour AGRID. À partir de
                        là, vous n’avez plus rien à effectuer, Medialog et AGRID se mettent en relation pour connecter
                        les deux logiciels.
                    </Typography>
                </>
            ),
        },
        {
            name: 'Clock',
            logo: ClockLogo,
            details: (
                <>
                    <Typography>
                        L'activation de votre intégration avec AGRID se fait via le <strong>AppConnector</strong> de
                        Clock PMS+.
                    </Typography>
                    <Typography>
                        Pour que l'utilisateur puisse l'activer, il doit avoir le droit utilisateur :{' '}
                        <em>"AppConnector : Activer / Désactiver les intégrations"</em>.
                    </Typography>
                    <ol>
                        <li>Allez dans le menu "Paramètres" - "AppConnector".</li>
                        <li>Recherchez AGRID par nom, ou dans la catégorie "Gestion des Opérations".</li>
                        <img src={Medialog1} alt="Clock PMS+ integration" style={{ maxWidth: '50%' }} />
                        <li>
                            Une fois que vous avez cliqué dessus, une fenêtre pop-up apparaîtra. Acceptez les conditions
                            en cochant les cases correspondantes, puis cliquez sur "Activer".
                        </li>
                        <img
                            src={Medialog2}
                            alt="Clock PMS+ integration"
                            style={{ maxWidth: '50%', alignItems: 'center' }}
                        />
                    </ol>
                    <Typography>
                        L'activation de l'intégration créera automatiquement un utilisateur API et enverra les
                        identifiants à l'email de livraison convenu lors de la certification.
                    </Typography>
                </>
            ),
        },
    ]

    const handleAccordionChange = (pmsName) => (event, isExpanded) => {
        setExpanded(isExpanded ? pmsName : false)
    }

    return (
        <div>
            <Typography variant="p" gutterBottom>
                Selon votre PMS, le processus de connexion à AGRID peut varier. Voici les étapes à suivre pour les PMS:
            </Typography>
            <List>
                {pmsList.map((pms) => (
                    <ListItem key={pms.name}>
                        <Accordion expanded={expanded === pms.name} onChange={handleAccordionChange(pms.name)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <img src={pms.logo} alt={pms.name} style={{ width: 20, height: 20, marginRight: 20 }} />
                                <Typography>{pms.name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>{pms.details}</AccordionDetails>
                        </Accordion>
                    </ListItem>
                ))}
            </List>
        </div>
    )
}
