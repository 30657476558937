export enum SiteTypeIds {
    COWORKING_SPACE = 1,
    HOTEL = 2,
    SUPERMARKET = 3,
    FITNESS_CENTER = 4,
    UNIVERSITY = 5,
    PRIMARY_SCHOOL = 6,
    OFFICE = 7,
    HOTEL_RESIDENCE = 8,
}

export enum SiteTypes {
    COWORKING_SPACE = 'Coworking Space',
    HOTEL = 'Hotel',
    SUPERMARKET = 'Supermarket',
    FITNESS_CENTER = 'Fitness_Center',
    UNIVERSITY = 'Universities',
    PRIMARY_SCHOOL = 'Primary School',
    OFFICE = 'Office',
    HOTEL_RESIDENCE = 'Appart Hôtel',
}
