import React, { useEffect, useState } from 'react'
import {
    Button,
    Grid,
    Card,
    CardContent,
    IconButton,
    Menu,
    Alert,
    Switch,
    FormControlLabel,
    ToggleButton,
    ToggleButtonGroup,
    Box,
    TextField,
} from '@mui/material'
import object_hash from 'object-hash'

import OfficeSvg from '@/assets/img/realtime/Office.svg'
import GroupSvg from '@/assets/img/realtime/group.svg'
import OtherSvg from '@/assets/img/realtime/other.svg'
import RoomSvg from '@/assets/img/realtime/room.svg'
import OpenSpace from '@/assets/img/realtime/open_space.png'
import CommonAreaSvg from '@/assets/img/realtime/Common_Area.svg'
import RestaurantSvg from '@/assets/img/realtime/restaurant.svg'
import TechnicalZoneSvg from '@/assets/img/realtime/Technical_Zone.svg'
import Meeting_Room from '@/assets/img/realtime/meeting.png'
import SettingsIcon from '@mui/icons-material/Settings'
import SpaceItem from './space-item.component'
import AirCon from '@/assets/img/realtime/air-conditioner.svg'
import Thermometer from '@/assets/img/realtime/thermometer.svg'
import Remote from '@/assets/img/realtime/remote.svg'
import { DndProvider, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
// import withScrolling from "react-dnd-scrolling";
import useLocale from '@/utils/locale/locale.hook'
import { event } from '@/utils/ga.utils'
import { TitleComponent, DescriptionComponent, PageTitleComponent } from '@/components/Basic/library'
import { textBlueLight } from '@/components/Basic/colors'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import GroupItem from './group-item.component'
import NotificationMenu from './notifications.component'
// const ScrollingComponent = withScrolling(Grid);
const ScrollingComponent = Grid

const Board = ({
    arr,
    categories,
    show,
    sensors,
    dataMeasurements,
    changeSpaceType,
    draggable,
    realtime,
    hideInactives,
    sitePilot,
    translations,
    updateCurrentGroup,
    currentGroup,
    constraints,

    hash,
    objectPms,
    bookings,
    search,
}) => {
    const [piloted, setPiloted] = useState(localStorage?.getItem(`${hash}_${arr}_piloted`) || 1)
    const [{ isOver }, drop] = useDrop({
        accept: 'CARD',
        drop: () => ({ name: arr }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    })

    const locale = useLocale()
    if (!arr) return null
    return (
        <Card
            ref={drop}
            style={{
                marginBottom: 20,
                border: isOver ? '1px solid rgb(66, 133, 244)' : '1px solid transparent',
            }}
        >
            <CardContent>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: 20,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            color: '#050C22',
                            fontSize: 14,
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: 500,
                        }}
                    >
                        {arr === 'Hotel_Room' && <img src={RoomSvg} style={{ marginRight: 10 }} />}
                        {arr === 'Common_Area' && <img src={CommonAreaSvg} style={{ marginRight: 10 }} />}
                        {arr === 'Restaurant' && <img src={RestaurantSvg} style={{ marginRight: 10 }} />}
                        {arr === 'Technical_Zone' && <img src={TechnicalZoneSvg} style={{ marginRight: 10 }} />}
                        {arr === 'Office' && <img src={OfficeSvg} style={{ marginRight: 10, width: 15 }} />}
                        {arr === 'Meeting_Room' && <img src={Meeting_Room} style={{ marginRight: 10, width: 15 }} />}
                        {arr === 'Open_Space' && <img src={OpenSpace} style={{ marginRight: 10, width: 15 }} />}
                        {arr === 'Other' && <img src={OtherSvg} style={{ marginRight: 10, width: 15 }} />}
                        <TitleComponent
                            title={`${locale?.['spaceTypes']?.[arr]} (${
                                categories?.[arr]?.filter(
                                    (el) =>
                                        !el?.IsSlave &&
                                        (sensors?.filter((sensor) => sensor.ObjectId === el.ObjectId)?.length > 0 ||
                                            !realtime)
                                )?.length || 0
                            })`}
                            style={{ fontSize: 15 }}
                        />
                    </div>
                    {realtime && (
                        <ToggleButtonGroup
                            color="primary"
                            value={parseInt(piloted)}
                            exclusive
                            onChange={() => {
                                // get all localstorage with key _piloted
                                const keys = Object.keys(localStorage).filter((key) => key.includes('_piloted'))
                                if (keys?.length > 50) {
                                    for (let i = 0; i < 10; i++) {
                                        const key = keys[i]
                                        localStorage.removeItem(key)
                                    }
                                }
                                localStorage.setItem(`${hash}_${arr}_piloted`, 1 - piloted)
                                setPiloted(1 - piloted)
                            }}
                            style={{ height: 45 }}
                        >
                            <ToggleButton value={1} style={{ fontSize: 10 }}>
                                {locale?.['realTime']?.['pilotOn']}
                            </ToggleButton>
                            <ToggleButton value={0} style={{ fontSize: 10 }}>
                                {locale?.['realTime']?.['pilotOff']}
                            </ToggleButton>
                        </ToggleButtonGroup>
                    )}
                </div>
                {!sitePilot && realtime && piloted === 1 && (
                    <Alert severity="info" style={{ marginBottom: 40 }}>
                        {categories?.[arr].length > 0
                            ? locale?.['realTime']?.['alert_1']
                            : locale?.['realTime']?.['alert_2']}
                    </Alert>
                )}
                <ScrollingComponent container rowSpacing={2} columnSpacing={2}>
                    {categories?.[arr]
                        ?.slice()
                        ?.sort((a, b) => {
                            return (
                                isFinite(a?.Name?.[0]) - isFinite(b?.Name?.[0]) ||
                                a?.Name?.localeCompare(b?.Name, undefined, {
                                    numeric: true,
                                    sensitivity: 'base',
                                })
                            )
                        })
                        ?.filter((el) => {
                            return search == '' || el?.Name?.toLowerCase().includes(search.toLowerCase())
                        })
                        ?.map((item, index) => {
                            return (
                                <SpaceItem
                                    key={item?.ObjectId}
                                    item={item}
                                    index={index}
                                    show={show}
                                    sensors={sensors}
                                    dataMeasurements={dataMeasurements}
                                    spaceType={arr}
                                    draggable={draggable}
                                    changeSpaceType={changeSpaceType}
                                    hideInactives={hideInactives}
                                    sitePilot={sitePilot}
                                    piloted={parseInt(piloted)}
                                    categories={categories}
                                    translations={translations}
                                    updateCurrentGroup={updateCurrentGroup}
                                    currentGroup={currentGroup}
                                    constraint={constraints?.[item?.ObjectId]}
                                    objectPms={objectPms}
                                    bookings={bookings}
                                />
                            )
                        })}
                </ScrollingComponent>
            </CardContent>
        </Card>
    )
}

const AlternativeRow = ({
    show,
    sensors,
    dataMeasurements,
    rawObjects,
    draggable,
    realtime,
    sitePilot,
    updateObject,
    translations,
    currentGroup,
    updateCurrentGroup,
    showSite,
    groups,
    selectedBuildingId,
    constraints,
    hash,
    objectPms,
    bookings,
    notifications,
}) => {
    const [onPercentage, setOnPercentage] = useState(null)
    const [averageTmp, setAverageTmp] = useState(null)
    const [averageOrderTmp, setAverageOrderTmp] = useState(null)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [hideInactives, setHideInactives] = useState(false)
    const locale = useLocale()
    const [search, setSearch] = useState('')

    const [categories, setCategories] = useState({
        Common_Area: [],
        Technical_Zone: [],
        Restaurant: [],
        Office: [],
        Hotel_Room: [],
        Meeting_Room: [],
        Other: [],
        Open_Space: [],
    })

    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    useEffect(() => {
        computeMeans(dataMeasurements)
    }, [dataMeasurements])

    useEffect(() => {
        if (rawObjects) {
            let newCategories = orderRawObjects(
                rawObjects.filter((el) => el.ObjectTypeId !== 1 && el.ObjectTypeId !== 2)
            ) /** filtering for buildings and sites */
            let new_hash = object_hash({
                categories: newCategories,
            })
            let old_hash = object_hash({
                categories,
            })

            if (new_hash !== old_hash) {
                setCategories(newCategories)
            }
        }
    }, [rawObjects])

    const computeMeans = (dataMeasurements) => {
        if (dataMeasurements?.length > 0) {
            /** computing on percentage */
            let targetSensors = sensors?.filter((el) => el?.DataTypeId === 228)
            let targetMeasurements = dataMeasurements.filter(
                (item) => targetSensors.map((el) => el.SensorId)?.indexOf(item?.SensorId) > -1
            )
            if (targetMeasurements.length > 0) {
                let sum = targetMeasurements
                    ?.filter((el) => el?.Value === 0 || el?.Value === 1)
                    .reduce(function (previousValue, currentValue) {
                        return previousValue + currentValue.Value
                    }, 0)
                setOnPercentage((sum / targetMeasurements.length) * 100)
            }
            let equipmentOn = targetSensors
                .filter((item) => {
                    let find = targetMeasurements?.find((el) => el.SensorId == item?.SensorId)
                    if (find?.Value === 1) return true
                    else return false
                })
                .map((el) => el?.EquipmentId)
            /** mode sensors */
            targetSensors = sensors?.filter((el) => el?.DataTypeId === 225)
            targetMeasurements = dataMeasurements.filter(
                (item) => targetSensors.map((el) => el.SensorId)?.indexOf(item?.SensorId) > -1
            )

            let equipmentNotFan = targetSensors
                .filter((item) => {
                    let find = targetMeasurements?.find((el) => el.SensorId == item?.SensorId)
                    if (find?.Value !== 4) return true
                    else return false
                })
                .map((el) => el?.EquipmentId)
            /** average tpmp */
            targetSensors = sensors?.filter((el) => el?.DataTypeId === 184)

            targetMeasurements = dataMeasurements
                .filter((item) => targetSensors.map((el) => el.SensorId)?.indexOf(item?.SensorId) > -1)
                ?.filter((el) => el?.Value)

            if (targetMeasurements.length > 0) {
                let sum = targetMeasurements.reduce(function (previousValue, currentValue) {
                    let value =
                        typeof currentValue.Value === 'string'
                            ? currentValue.Value.replace(',', '.')
                            : currentValue.Value
                    return previousValue + parseFloat(value)
                }, 0)

                setAverageTmp(sum / targetMeasurements.length)
            }

            /** average tmp order */
            targetSensors = sensors
                ?.filter((el) => el?.DataTypeId === 226)
                ?.filter((el) => equipmentOn?.indexOf(el?.EquipmentId) > -1)
                ?.filter((el) => equipmentNotFan?.indexOf(el?.EquipmentId) > -1)
            targetMeasurements = dataMeasurements
                .filter((item) => targetSensors.map((el) => el.SensorId)?.indexOf(item?.SensorId) > -1)
                ?.filter((el) => el?.Value)
            if (targetMeasurements.length > 0) {
                let sum = targetMeasurements.reduce(function (previousValue, currentValue) {
                    return previousValue + parseInt(currentValue.Value)
                }, 0)
                setAverageOrderTmp(sum / targetMeasurements.length)
            }
        } else {
            setAverageOrderTmp(null)
            setAverageTmp(null)
            setOnPercentage(null)
        }
    }

    const orderRawObjects = (raw) => {
        let output = {
            Common_Area: [],
            Hotel_Room: [],
            Technical_Zone: [],
            Meeting_Room: [],
            Open_Space: [],
            Restaurant: [],
            Office: [],
            Other: [],
        }
        for (let i = 0; i < raw.length; i++) {
            let obj = raw[i]
            if (obj.SpaceType in output) {
                output[obj.SpaceType].push(obj)
            } else output[obj.SpaceType] = [{ ...obj }]
        }
        return output
    }

    const changeSpaceType = (item, newSpaceType) => {
        // let output = { ...categories };
        // let oldSpaceType = item.SpaceType;
        // if (oldSpaceType === newSpaceType) return;
        // let newItem = { ...item, SpaceType: newSpaceType };
        // output[newSpaceType] = [...output[newSpaceType], newItem];
        // output[oldSpaceType] = output[oldSpaceType].filter(
        //   (el) => el.ObjectId !== item.ObjectId
        // );
        // setCategories(output);
        event('SpaceType', `${item?.Name} -> ${newSpaceType}`)
        updateObject(item.ObjectId, newSpaceType)
    }

    return (
        <div>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Grid container spacing={{ md: 5, sm: 2, xs: 2 }} style={{ marginBottom: 30 }}>
                    {onPercentage !== null && (
                        <Grid item xs={12} sm={4}>
                            <Card style={{ height: '100%' }}>
                                <CardContent style={{ display: 'flex', position: 'relative' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                        }}
                                    >
                                        <PageTitleComponent
                                            title={`${onPercentage.toFixed(1)}%`}
                                            style={{ fontSize: 35 }}
                                        />

                                        <DescriptionComponent
                                            content={locale?.['realTime']?.['machinesOn']}
                                            style={{ fontSize: 12, color: textBlueLight }}
                                        />
                                    </div>
                                    <img
                                        src={AirCon}
                                        style={{
                                            width: 30,
                                            position: 'absolute',
                                            top: 10,
                                            right: 10,
                                        }}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                    {averageTmp !== null && (
                        <Grid item xs={12} sm={4}>
                            <Card style={{ height: '100%' }}>
                                <CardContent style={{ display: 'flex', position: 'relative' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                        }}
                                    >
                                        <PageTitleComponent
                                            title={`${averageTmp.toFixed(1)} °C`}
                                            style={{ fontSize: 35 }}
                                        />
                                        <DescriptionComponent
                                            content={locale?.['realTime']?.['averageTmp']}
                                            style={{ fontSize: 12, color: textBlueLight }}
                                        />
                                    </div>
                                    <img
                                        src={Thermometer}
                                        style={{
                                            width: 30,
                                            position: 'absolute',
                                            top: 10,
                                            right: 10,
                                        }}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                    {averageOrderTmp !== null && (
                        <Grid item xs={12} sm={4}>
                            <Card style={{ height: '100%' }}>
                                <CardContent style={{ display: 'flex', position: 'relative' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                        }}
                                    >
                                        <PageTitleComponent
                                            title={`${averageOrderTmp ? averageOrderTmp.toFixed(1) : '-'} °C`}
                                            style={{ fontSize: 35 }}
                                        />
                                        <DescriptionComponent
                                            content={locale?.['realTime']?.['averageAsked']}
                                            style={{ fontSize: 12, color: textBlueLight }}
                                        />
                                    </div>
                                    <img
                                        src={Remote}
                                        style={{
                                            width: 30,
                                            position: 'absolute',
                                            top: 10,
                                            right: 10,
                                        }}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                {/** search bar for  */}
                <TextField
                    label="Chercher une zone"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{
                        width: '100%',
                        backgroundColor: 'white',
                        marginTop: 20,
                        marginBottom: 20,
                    }}
                />
            </Box>
            {dataMeasurements?.length > 0 && (
                <Grid container justifyContent="space-between" sx={{ mb: 2 }}>
                    {dataMeasurements?.length > 0 && (
                        <Grid container item xs={6}>
                            <Grid item>
                                <IconButton
                                    id="demo-positioned-button"
                                    aria-controls="demo-positioned-menu"
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    <SettingsIcon sx={{ color: 'grey.600' }} />
                                </IconButton>
                                <Menu
                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    <div style={{ padding: '5px 15px' }}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={hideInactives}
                                                    onChange={() => {
                                                        setHideInactives(!hideInactives)
                                                        handleClose()
                                                    }}
                                                />
                                            }
                                            label={locale?.['realTime']?.['hide']}
                                        />
                                    </div>
                                </Menu>
                            </Grid>
                            <Grid item>
                                <NotificationMenu notifications={notifications} />
                            </Grid>
                        </Grid>
                    )}
                    {!selectedBuildingId && (
                        <Grid item>
                            <Button onClick={showSite} endIcon={<ArrowRightAltIcon />}>
                                Pilotage du site
                            </Button>
                        </Grid>
                    )}
                </Grid>
            )}
            {groups?.length === 0 || !groups || draggable ? null : (
                <Card
                    //ref={useRef}
                    style={{
                        marginBottom: 20,
                        border: '1px solid rgb(66, 133, 244)',
                        boxShadow: 'none',
                    }}
                >
                    <CardContent>
                        <div
                            style={{
                                display: 'flex',
                                marginBottom: 20,
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    color: '#050C22',
                                    fontSize: 14,
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontWeight: 500,
                                }}
                            >
                                <img src={GroupSvg} style={{ marginRight: 10, width: 20 }} />
                                <TitleComponent title={'Groupes'} style={{ fontSize: 15 }} />
                            </div>
                        </div>
                        <Grid container spacing={2}>
                            {groups?.map((item) => {
                                return <GroupItem key={item?.name} item={item} show={show} sensors={sensors} />
                            })}
                        </Grid>
                    </CardContent>
                </Card>
            )}
            <DndProvider backend={HTML5Backend}>
                {categories &&
                    Object.keys(categories)
                        ?.filter((cat) => {
                            return draggable || categories[cat]?.length > 0
                        })
                        .map((arr, index) => {
                            return (
                                <Board
                                    key={`${arr}_${index}`}
                                    arr={arr}
                                    categories={categories}
                                    sensors={sensors}
                                    dataMeasurements={dataMeasurements}
                                    show={show}
                                    changeSpaceType={changeSpaceType}
                                    draggable={draggable}
                                    realtime={realtime}
                                    hideInactives={hideInactives}
                                    sitePilot={sitePilot}
                                    translations={translations}
                                    currentGroup={currentGroup}
                                    updateCurrentGroup={updateCurrentGroup}
                                    constraints={constraints}
                                    hash={hash}
                                    objectPms={objectPms}
                                    bookings={bookings}
                                    search={search}
                                />
                            )
                        })}
                <Board />
            </DndProvider>
        </div>
    )
}

export default AlternativeRow
