import React from 'react'

/** ICONS */
import { Alert, Button } from '@mui/material'
import useLocale from '../../utils/locale/locale.hook'
const NoDataComponent = (props) => {
    const locale = useLocale()
    return (
        <Alert
            severity="warning"
            style={{ fontSize: 12 }}
            action={
                <Button
                    color="inherit"
                    size="small"
                    onClick={() => {
                        console.log('props', props)
                        props?.navigate('/help')
                    }}
                >
                    AIDE
                </Button>
            }
        >
            {locale?.['noData']}
        </Alert>
    )
}

export default NoDataComponent
