/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useContext, createContext, FC } from 'react'
import { makeid, sleep } from '@/utils/global.utils'
import { insertAction } from '@/utils/realtime.utils'
import { sendAction } from '@/services/sensors.service'
const RealtimeContext = createContext({})
import { useParams } from 'react-router-dom'
import { useSite } from '../useSite'
import { NotFoundError } from '@/utils/error.utils'
import { getGroups } from '@/services/constraints.service'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { event } from '@/utils/ga.utils'

type Modification = any

export const useRealtime = () => {
    const context = useContext(RealtimeContext)

    if (!context) {
        throw new Error('useRealtime must be used within a RealtimeProvider')
    }
    return context
}

export const RealtimeProvider: FC = ({ children }) => {
    const { site, objectsQuery } = useSite()
    const { objectId } = useParams<{ objectId?: string }>()
    const navigate = useNavigate()
    const [confirmDialog, setConfirmDialog] = useState(false)
    if (!site) {
        throw new Error('siteHash is required')
    }
    const [modifications, setModifications] = useState<Modification[]>([])
    const [sendLoader, setSendLoader] = useState(false)
    const addModification = (actuatorId: number, value: any, title: any, sensors: any) => {
        const array: any[] = [...modifications]
        const exist = array.find((el) => el.id === actuatorId)
        if (exist) {
            for (let i = 0; i < array.length; i++) {
                if (array[i].id === actuatorId) {
                    array[i].value = value
                    array[i].title = title
                    break
                }
            }
        } else {
            array.push({
                id: actuatorId,
                value,
                title,
                actuatorId: actuatorId,
                sensors,
                createdAt: new Date(),
                hash: makeid(),
            })
        }
        setModifications(array)
    }
    const clearModifications = () => {
        setModifications([])
    }

    const confirmModifications = async (hash: string) => {
        setSendLoader(true)
        if (modifications && modifications?.length > 0) {
            const send = insertAction(modifications)
            if (send) {
                await sendAction({
                    groupHashes: hash ? [hash] : [],
                    modifications,
                    hash: site.hash,
                    fromDashboard: true,
                    selectedObject: { Name: 'TODO' },
                })
            }
        }
        await sleep(5000)
        setSendLoader(false)
        event('SendAction', `${site.name} - ${objectId}`)
        navigate(-1)
    }

    const saveModifications = async (hash: string) => {
        if (object?.objectTypeId === 1) {
            setConfirmDialog(true)
        } else {
            await confirmModifications(hash)
        }
    }

    const groupsQuery = useQuery({
        queryKey: ['groups-for-site', site.hash],
        queryFn: async () => {
            const res = await getGroups(site.hash)
            return res?.groups
        },
        staleTime: 1000 * 60 * 10,
    })

    const objects = objectsQuery.data || []
    const object = objects.find((obj) => String(obj.objectId) === objectId)
    if (objectId && !objectsQuery.isLoading && !object) {
        throw new NotFoundError(`Object with id ${objectId} not found`)
    }

    return (
        <RealtimeContext.Provider
            value={{
                addModification,
                clearModifications,
                saveModifications,
                confirmModifications,
                site,
                isLoading: objectsQuery.isLoading,
                object,
                confirmDialog,
                setConfirmDialog,
                sendLoader,
                setSendLoader,
                groups: groupsQuery.data || [],
            }}
        >
            {children}
        </RealtimeContext.Provider>
    )
}
