import { Typography, TypographyProps } from '@mui/material'
import { formatMatches } from '@/utils/formatMatches'

type MarkStyle = TypographyProps['sx']
interface HighlightProps extends TypographyProps {
    text: string
    matchedIndexRanges?: readonly number[][]
    markStyle?: MarkStyle
}

const defaultMarkStyle: MarkStyle = {
    backgroundColor: 'none',
    background: 'none',
    fontWeight: 'bold',
    fontSize: 'inherit',
    fontStyle: 'italic',
}

const Highlight: React.FC<HighlightProps> = ({
    text,
    matchedIndexRanges = [],
    markStyle = defaultMarkStyle,
    ...props
}) => {
    if (!matchedIndexRanges.length) {
        return <Typography {...props}>{text}</Typography>
    }

    const parts = formatMatches(text, matchedIndexRanges)

    return (
        <Typography {...props}>
            {parts.map((part, index) =>
                part.isMatch ? (
                    <Typography component="mark" key={index} sx={markStyle}>
                        {part.text}
                    </Typography>
                ) : (
                    <span key={index}>{part.text}</span>
                )
            )}
        </Typography>
    )
}

export default Highlight
