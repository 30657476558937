import { FC } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { SiteRoutes } from './siteRoutes'
import DocumentsPage from './documents'
import DashboardPage from './dashboard'
import RealtimePage from './realTime'
import ConstraintsPage from './constraints'
import Home from './home'
import SiteLayout from './layout'

const DEFAULT_ROUTE = ''

const SitePage: FC = () => {
    return (
        <Routes>
            <Route element={<SiteLayout />} path="/">
                <Route path="/" element={<Home />} />
                <Route path={`${SiteRoutes.DASHBOARD}/*`} element={<DashboardPage />} />
                <Route path={`${SiteRoutes.REALTIME}/*`} element={<RealtimePage />} />
                <Route path={`${SiteRoutes.CONSTRAINTS}/*`} element={<ConstraintsPage />} />
                <Route path={SiteRoutes.DOCUMENTS} element={<DocumentsPage />} />
                <Route path="*" element={<Navigate to={DEFAULT_ROUTE} />} />
            </Route>
        </Routes>
    )
}

export default SitePage
