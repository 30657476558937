import { FC } from 'react'
import { useSiteDashboardData } from '../useSiteDashboardData'
import { ModeHistoryComponent } from '../components/mode-history.component'
import { Box, Skeleton, Typography } from '@mui/material'
import Indicators, { IndicatorsLoader, filterObjectData, IndicatorKeys } from './Indicators'
import { ObjectDataPoint } from './objectDataPoint'
import { TemperatureChart } from './temperature.chart'
import moment from 'moment'
import { NotFoundError } from '@/utils/error.utils'

const LoadingSkeleton: FC = () => (
    <Box>
        <Typography variant="h3" gutterBottom width="50%">
            <Skeleton />
        </Typography>
        <IndicatorsLoader />
    </Box>
)

function formatChartData(objectData: ObjectDataPoint[]) {
    return objectData.map((point) => {
        return {
            Zone_OnPercentage:
                typeof point[IndicatorKeys.ON_PERCENTAGE] === 'number'
                    ? point[IndicatorKeys.ON_PERCENTAGE] * 100
                    : undefined,
            tInt: point[IndicatorKeys.T_INT],
            tExt: point['TOutMean'],
            setTemp: point[IndicatorKeys.T_SETPOINT],
            label: moment(point.Date).format('DD MMM'),
        }
    })
}

const ObjectDashboard: FC = () => {
    const { object, objectId, objectsQuery, siteHash, dashboardQuery } = useSiteDashboardData()

    if (objectsQuery.isLoading || dashboardQuery.isLoading || !dashboardQuery.data) {
        return <LoadingSkeleton />
    }
    if (!object) {
        throw new NotFoundError(`Object with id ${objectId} not found`)
    }
    const siteData: ObjectDataPoint[] = dashboardQuery.data.data
    const objectData = filterObjectData(siteData, object.objectHash)

    return (
        <Box>
            <Indicators siteData={siteData} objectHash={object.objectHash} sx={{ mb: 2 }} />
            <TemperatureChart data={formatChartData(objectData)} />
            <ModeHistoryComponent hash={siteHash} objectId={objectId} />
        </Box>
    )
}

export default ObjectDashboard
