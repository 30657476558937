import { VFC } from 'react'
import { Typography, Box, Chip } from '@mui/material'
import { SiteMetaData } from '@/types/siteMetadata'
import SiteTypeChip from './components/SiteTypeChip'
import PlaceIcon from '@mui/icons-material/Place'
import SquareFootIcon from '@mui/icons-material/SquareFoot'
import falbackSiteImage from '@/assets/img/site.png'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

interface SiteHeaderProps {
    site: SiteMetaData
}

const IMAGE_HEIGHT = 300

const formatAddress = ({ address, city, zipCode, country }: SiteMetaData) => {
    const addressParts = [address]
    if (zipCode && !address?.includes(zipCode)) {
        addressParts.push(zipCode)
    }
    if (city && !address?.includes(city)) {
        addressParts.push(city)
    }
    if (country && !address?.includes(country) && !city?.includes(country)) {
        addressParts.push(country)
    }

    return addressParts.filter(Boolean).join(', ')
}
const SiteHeader: VFC<SiteHeaderProps> = ({ site }) => {
    const { t } = useTranslation('site', { keyPrefix: 'home' })
    const { name, startDatePilot, surface, siteType, siteTypeId } = site
    return (
        <>
            <Box
                sx={{
                    height: IMAGE_HEIGHT,
                    backgroundImage: `url(${site.pictureUrl ? site.pictureUrl : falbackSiteImage})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                }}
            >
                {/* <Typography
                    color="primary.light"
                    variant="h2"
                    sx={({ shape, spacing }) => ({
                        backdropFilter: 'blur(8px)',
                        p: 1,
                        borderRadius: spacing(1, 0, 0, 0),
                    })}
                >
                    {site.name}
                </Typography> */}
            </Box>
            <Box p={2}>
                <Typography variant="h2" gutterBottom>
                    {name}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap', mb: 2 }}>
                    <SiteTypeChip siteTypeId={siteTypeId} siteType={siteType} sx={{ pl: 0.5 }} />
                    <Chip icon={<SquareFootIcon />} label={`${surface} m²`} />
                    <Chip icon={<PlaceIcon />} label={formatAddress(site)} />
                </Box>

                <Typography variant="body1">
                    <b>{t('agridCopilot')}&thinsp;:</b>{' '}
                    {startDatePilot
                        ? t('startedOnDate', { date: moment(startDatePilot).format('LL') })
                        : t('notStarted')}
                </Typography>
            </Box>
        </>
    )
}

export default SiteHeader
