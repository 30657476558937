/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from 'react'
import { Box, Card, CardContent, Button, Rating, TextField, Typography } from '@mui/material'
import { DataGrid, GridColumns, useGridApiContext } from '@mui/x-data-grid'
import { darken, lighten, styled } from '@mui/material/styles'
import { PaletteMode } from '@mui/material'
import {
    SiteData,
    PerimeterExtendedSiteData,
    DpeFullLabel,
    categoryFilterKeyTranslations,
    SeriesTypes,
    EnergySubtypes,
} from '../types'
import { CustomProgressBar } from './users.component'
import sumBy from 'lodash/sumBy'
import DpeLabel from '@/views/site/dashboard/components/dpeLabel.component'

const getBackgroundColor = (color: string, mode: PaletteMode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7)

const getHoverBackgroundColor = (color: string, mode: PaletteMode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6)

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-row': {
        backgroundColor: 'white',
    },
    '& .MuiDataGrid-columnHeaders': {
        backgroundColor: theme.palette.background.paper,
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
    },
    '& .super-app-theme--average': {
        backgroundColor: getBackgroundColor('#ffe082', theme.palette.mode),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor('#ffe082', theme.palette.mode),
        },
    },
}))

const getSeriesSum = (series: SiteData['series'], type: SeriesTypes, subType: string | null = null): number => {
    const s = series.find((s) => s.type === type && s.subType === subType)
    if (!s) return 0
    return sumBy(s.data, 'value')
}

const consumptionDataFormatter = (value: number, unit: string) =>
    `${Intl.NumberFormat('fr-FR').format(Math.round(value))} ${unit}`

const addMetrics = (site: SiteData) => {
    const totals: Record<string, number> = {
        totalWater: getSeriesSum(site.series, SeriesTypes.WATER),
        totalConsumptionFinal: getSeriesSum(site.series, SeriesTypes.ENERGY_TOTAL, 'final'),
        totalConsumptionPrimary: getSeriesSum(site.series, SeriesTypes.ENERGY_TOTAL, 'primary'),
        totalCO2: getSeriesSum(site.series, SeriesTypes.ENERGY_TOTAL, 'co2'),
        totalElectricity: getSeriesSum(site.series, SeriesTypes.ENERGY, EnergySubtypes.ELECTRICITY),
        totalGas: getSeriesSum(site.series, SeriesTypes.ENERGY, EnergySubtypes.GAS),
        totalHeat: getSeriesSum(site.series, SeriesTypes.ENERGY, EnergySubtypes.HEAT),
        totalOther: getSeriesSum(site.series, SeriesTypes.ENERGY, EnergySubtypes.OTHER),
    }

    return {
        ...site,
        ...totals,
    }
}

const DpeLabelWithValue: FC<{ dpe: DpeFullLabel; type: string }> = ({ dpe, type }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <DpeLabel
            type={type === 'emission' ? 'greenhouse' : 'consumption'}
            size="small"
            width={60}
            dpeClass={dpe.label}
        />
        <Typography sx={{ fontSize: 'inherit' }}>{consumptionDataFormatter(dpe.value, dpe.unit)}</Typography>
    </Box>
)

export const PerimeterMarker: FC<{ color: string; label: string | number }> = ({ color, label }) => (
    <Typography
        component="span"
        variant="subtitle2"
        sx={(theme) => ({
            backgroundColor: color,
            borderRadius: theme.shape.borderRadius / 2,
            minWidth: theme.spacing(3),
            minHeight: theme.spacing(3),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        })}
    >
        {label}
    </Typography>
)

const columns = (showPerimeterMarkers: boolean): GridColumns => [
    {
        field: 'name',
        headerName: 'Nom',
        width: 200,
        renderCell: (params) => {
            const { value, row } = params
            const { perimeterIndex, perimeter } = row
            return (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {showPerimeterMarkers && <PerimeterMarker color={perimeter.color} label={perimeterIndex + 1} />}
                    <strong>{value}</strong>
                </Box>
            )
        },
    },
    {
        field: 'dpe.consumption.value',
        headerName: 'DPE',
        width: 250,
        valueGetter: (params) => params.row.dpe.consumption.value,
        renderCell: (params) => {
            const dpeLabel = params.row.dpe.consumption
            return <DpeLabelWithValue dpe={dpeLabel} type="consumption" />
        },
    },

    { field: 'brand', headerName: categoryFilterKeyTranslations.brand, width: 200 },
    { field: 'subBrand', headerName: categoryFilterKeyTranslations.subBrand, width: 200 },

    // { field: 'consoBrute', headerName: 'Consommation brute (kWh)', type: 'number', width: 220 },
    // { field: 'consoSource', headerName: 'Consommation par source (kWh)', type: 'number', width: 240 },
    // { field: 'consoTempCorr', headerName: 'Consommation DJU (kWh)', type: 'number', width: 220 },
    // { field: 'consoM2', headerName: 'Consommation par m² (kWh)', type: 'number', width: 220 },
    // { field: 'consoChambre', headerName: 'Consommation par chambre (kWh)', type: 'number', width: 220 },
    {
        field: 'stars',
        headerName: 'Classification',
        type: 'number',
        width: 150,
        editable: false,
        sortable: true,
        renderCell: (params) => (
            <Rating
                value={params.value}
                readOnly
                size="small"
                color="grey"
                precision={0.5}
                sx={{
                    '& .MuiRating-iconEmpty': {
                        visibility: 'hidden',
                    },
                    '& .MuiRating-iconFilled': {
                        color: 'primary.main',
                        opacity: 0.9,
                    },
                }}
            />
        ),
    },
    {
        field: 'completionRate',
        headerName: 'Data collection',
        width: 150,
        renderCell: (params) => {
            return <CustomProgressBar progress={params.value * 100} />
        },
    },
    { field: 'rooms', headerName: 'Nombre de chambres', type: 'number', width: 180, editable: true },
    { field: 'surface', headerName: 'Surface (m²)', type: 'number', width: 150, editable: true },
    // { field: 'occupation', headerName: 'Occupation (nuitées)', type: 'number', width: 200, editable: true },
    { field: 'travaux', headerName: 'Travaux (montant)', type: 'number', width: 180, editable: true },
    {
        field: 'totalWater',
        headerName: 'Consommation eau',
        valueFormatter: (params) => consumptionDataFormatter(params.value, 'm³'),
        width: 200,
    },
    {
        field: 'totalElectricity',
        headerName: 'Consommation élec.',
        valueFormatter: (params) => consumptionDataFormatter(params.value, 'kWh'),
        width: 200,
    },
    {
        field: 'totalGas',
        headerName: 'Consommation gaz',
        valueFormatter: (params) => consumptionDataFormatter(params.value, 'kWh'),
        width: 200,
    },
    {
        field: 'totalHeat',
        headerName: 'Consommation chaleur',
        valueFormatter: (params) => consumptionDataFormatter(params.value, 'kWh'),
        width: 200,
    },
    {
        field: 'totalOther',
        headerName: 'Consommation autre',
        valueFormatter: (params) => consumptionDataFormatter(params.value, 'kWh'),
        width: 200,
    },
    {
        field: 'totalConsumptionFinal',
        headerName: 'Consommation EF',
        valueFormatter: (params) => consumptionDataFormatter(params.value, 'kWh'),
        width: 200,
    },
    {
        field: 'totalConsumptionPrimary',
        headerName: 'Consommation EP',
        valueFormatter: (params) => consumptionDataFormatter(params.value, 'kWh'),
        width: 200,
    },
    {
        field: 'totalCO2',
        headerName: 'Émissions CO2',
        valueFormatter: (params) => consumptionDataFormatter(params.value, 'kg'),
        width: 200,
    },

    {
        field: 'dpe.emission.value',
        headerName: 'GES',
        width: 250,
        valueGetter: (params) => params.row.dpe.emission.value,
        renderCell: (params) => {
            const dpeLabel = params.row.dpe.emission
            return <DpeLabelWithValue dpe={dpeLabel} type="emission" />
        },
    },
]

const CustomExporter: FC<{ setExportCsvMethod: (method: () => void) => void }> = ({ setExportCsvMethod }) => {
    const apiRef = useGridApiContext()
    setExportCsvMethod(() => apiRef.current.exportDataAsCsv({ fileName: 'report.csv' }))
    return (
        <Button sx={{ display: 'none' }} onClick={() => apiRef.current.exportDataAsCsv({ fileName: 'connect.csv' })}>
            Export as csv
        </Button>
    )
}

interface SortableDataGridProps {
    hotels: PerimeterExtendedSiteData[]
    showPerimeterMarkers?: boolean
    setExportCsvMethod: (method: () => void) => void
}

const SortableDataGrid: FC<SortableDataGridProps> = ({ hotels, showPerimeterMarkers = false, setExportCsvMethod }) => {
    const [pageSize, setPageSize] = useState(25)
    const [searchQuery, setSearchQuery] = useState('')
    const [handlingSearch, setHandlingSearch] = useState(false)

    const getRowClassName = (params: any) => {
        return params.row.average ? 'super-app-theme--average' : ''
    }
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (handlingSearch) return
        setHandlingSearch(true)
        setSearchQuery(event.target.value)
        setHandlingSearch(false)
    }
    const filteredHotels = hotels.filter((hotel) => hotel.name.toLowerCase().includes(searchQuery.toLowerCase()))

    return (
        <>
            <Card>
                <CardContent>
                    <TextField
                        label="Rechercher"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        style={{ marginBottom: 20 }}
                        size="small"
                    />
                    <StyledDataGrid
                        autoHeight
                        rows={filteredHotels.map((h) => addMetrics(h))}
                        columns={columns(showPerimeterMarkers)}
                        getRowId={(row) => `${row.perimeterIndex}-${row.id}`}
                        pageSize={pageSize}
                        rowsPerPageOptions={[10, 25, 50]}
                        pagination
                        disableSelectionOnClick
                        getRowClassName={getRowClassName}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        components={{
                            Footer: () => <CustomExporter setExportCsvMethod={setExportCsvMethod} />,
                        }}
                    />
                </CardContent>
            </Card>
        </>
    )
}

export default SortableDataGrid
