import { Card, CardContent, LinearProgress, Skeleton, CardActions } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getEquipments } from '@/services/realtime.service'
import useLocale from '@/utils/locale/locale.hook'
import SiteTelecommand from './components/site.telecommand'
import ObjectTelecommand from './components/telecommand.component'
import { ForceResetComponent } from '@/components/Site/force-reset.component'
import { useRealtime } from './useRealtime'
import SendLoader from './components/SendLoader'

const ZonePageLoader = () => <Skeleton variant="rectangular" width="100%" height={500} />

const ZonePage = () => {
    const {
        addModification,
        clearModifications,
        saveModifications,
        site: selectedSite,
        isLoading,
        object,
        groups,
        sendLoader,
    } = useRealtime()

    const [equipments, setEquipments] = useState([])
    const [familyTypes, setFamilyTypes] = useState({})
    const [loading, setLoading] = useState(true)

    const locale = useLocale()
    useEffect(() => {
        clearModifications()
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [object, selectedSite])

    const fetchData = async () => {
        const objectId = object?.objectId
        setLoading(true)
        if (selectedSite?.hash && objectId) {
            let res = await getEquipments({
                hash: selectedSite.hash,
                objectId: objectId,
            })

            if (res && res.equipments) {
                let formattedEquipments = await formatEquipments(res.equipments)
                let familyTypes = await extractFamilies(res.equipments)
                setFamilyTypes(familyTypes)
                setEquipments(formattedEquipments)
            }
        }
        setLoading(false)
    }

    const extractFamilies = async (data) => {
        let output = {}
        for (let i = 0; i < data.length; i++) {
            let equipment = data[i]
            let sensors = equipment.Sensors
            let actuators = equipment.Actuators
            ;[sensors, actuators].map((element, index) => {
                for (let j = 0; j < element.length; j++) {
                    let item = element[j]
                    let keyName = index === 0 ? 'Sensors' : 'Actuators'
                    // eslint-disable-next-line no-prototype-builtins
                    if (!output.hasOwnProperty(item['DataTypeFamilyName'])) {
                        output[item['DataTypeFamilyName']] = {
                            Sensors: [],
                            Actuators: [],
                            DataTypeFamilyId: item.DataTypeFamilyId,
                        }
                        output[item['DataTypeFamilyName']][keyName].push({
                            ...item,
                            sensors: [item.SensorId],
                            cmdIds: [item.ActuatorId],
                            //cmdIds: [item.IdLocal_Hub],
                        })
                    } else {
                        let exist = output[item['DataTypeFamilyName']][keyName].find(
                            (el) => el.DataTypeId === item.DataTypeId
                        )
                        if (exist) {
                            exist.sensors.push(item.SensorId)
                            if (item.ActuatorId) {
                                exist.cmdIds.push(item.ActuatorId)
                                //exist.cmdIds.push(item.IdLocal_Hub);
                            }
                        } else {
                            output[item['DataTypeFamilyName']][keyName].push({
                                ...item,
                                sensors: [item.SensorId],
                                cmdIds: [item.ActuatorId],
                                //cmdIds: [item.IdLocal_Hub],
                            })
                        }
                    }
                }
            })
        }
        return output
    }

    const formatEquipments = async (data) => {
        let output = {}
        for (let i = 0; i < data.length; i++) {
            let element = data[i]
            output[element['EquipmentId']] = {
                Name: element.Name,
                EquipmentId: element.EquipmentId,
                DataTypeFamilyId: element.DataTypeFamilyId,
                Sensors: element.Sensors.map((el) => {
                    return { ...el, sensors: [el.SensorId] }
                }),
                Actuators: element.Actuators.map((el) => {
                    return {
                        ...el,
                        sensors: [el.SensorId],
                        cmdIds: [el.IdLocal_Hub],
                    }
                }),
            }
        }

        return output
    }
    if (isLoading || loading) {
        return <ZonePageLoader />
    }
    return (
        <>
            <Card sx={{ p: 1 }}>
                <CardContent>
                    {!(object?.objectTypeId === 1) &&
                        Object.keys(familyTypes).map((keyName, index) => {
                            return (
                                <ObjectTelecommand
                                    key={`equipment-${keyName}-${index}`}
                                    sensors={
                                        {
                                            ...familyTypes[keyName],
                                            Name: keyName,
                                        }.Sensors
                                    }
                                    equipment={{
                                        ...familyTypes[keyName],
                                        Name: keyName,
                                    }}
                                    addModification={addModification}
                                    objectId={object?.objectId}
                                    hash={selectedSite?.hash}
                                    items={groups.filter((el) => el?.objects?.indexOf(object.objectId) > -1)}
                                    saveModifications={saveModifications}
                                />
                            )
                        })}

                    {!(object?.objectTypeId === 1) &&
                        Object.keys(familyTypes).length === 0 &&
                        Object.keys(equipments).length === 0 &&
                        loading && <LinearProgress />}
                    {!(object?.objectTypeId === 1) &&
                        Object.keys(familyTypes).length === 0 &&
                        Object.keys(equipments).length === 0 &&
                        !loading &&
                        !(object?.objectTypeId === 1) && <span>{locale?.['realTime']?.['noData']}</span>}
                    {object?.objectTypeId === 1 && (
                        <SiteTelecommand
                            addModification={addModification}
                            style={{ paddingTop: 15 }}
                            saveModifications={saveModifications}
                            items={groups.filter((el) => el?.objects?.indexOf(object.objectId) > -1)}
                        />
                    )}
                </CardContent>
                {[179, 180, 181, 182, 504]?.indexOf(selectedSite?.siteId) > -1 && (
                    <CardActions>
                        <ForceResetComponent object={object} constraints={null} selectedSite={selectedSite} />
                    </CardActions>
                )}
            </Card>
            <SendLoader open={sendLoader} />
        </>
    )
}

export default ZonePage
