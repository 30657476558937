import { VFC } from 'react'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'
import Fallback from '../fallback.component'
import SiteHeader from '../siteHeader.component'

const ConstraintsLayout: VFC = () => {
    const { t } = useTranslation('site')

    return (
        <Box>
            <SiteHeader title={t('navLinks.constraints')} />
            <ErrorBoundary fallbackRender={Fallback}>
                <Outlet />
            </ErrorBoundary>
        </Box>
    )
}

export default ConstraintsLayout
