import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import ConstraintsLayout from './layout'
import SiteConstraintsPage from './constraints.page'

const Constraints: FC = () => {
    return (
        <Routes>
            <Route element={<ConstraintsLayout />} path="/">
                {/* <Route path="/" element={<SiteConstraintsPage />} /> */}
                <Route path=":objectId?" element={<SiteConstraintsPage />} />
                {/* @TODO: split component between site and page */}
            </Route>
        </Routes>
    )
}

export default Constraints
