import FallbackTemplate from './template.component'
import MapIcon from '@mui/icons-material/Map'
import { useTranslation } from 'react-i18next'

const NotFound = () => {
    const { t } = useTranslation('fallback', { keyPrefix: 'notFound' })
    return <FallbackTemplate title={t('title')} description={t('description')} icon={<MapIcon fontSize="inherit" />} />
}

export default NotFound
