import uFuzzy, { Options as UFuzzyOptions } from '@leeoniya/ufuzzy'
import chunk from 'lodash/chunk'

interface FuzzyMatch<T = unknown> {
    item: T
    ranges: [number, number][]
}

export const fuzzyMatch = <T = unknown>(
    items: T[],
    key: keyof T,
    search: string,
    options: UFuzzyOptions = {}
): FuzzyMatch<T>[] => {
    if (!search) {
        return items.map((item) => ({ item, ranges: [] }))
    }
    const uf = new uFuzzy(options)

    const haystack = items.map((item) => uFuzzy.latinize(item[key] as string))
    const [matchingIndexes, matchesInfo] = uf.search(haystack, uFuzzy.latinize(search))
    return (
        matchingIndexes?.map((index, i) => {
            const item = items[index]
            const ranges = matchesInfo?.ranges[i] || []
            return { item, ranges: chunk(ranges, 2) as [number, number][] }
        }) || []
    )
}
