import { Card, CardContent, IconButton, Dialog, DialogContent, TextField, Alert } from '@mui/material'
import React, { useState } from 'react'
import { CardTitle } from './card-title.component'
import { LineChartComponent } from './line.component'
import SettingsIcon from '@mui/icons-material/Settings'

export const ScatterComponent = ({
    series,
    setSelectedZone,
    setScatterFilterPercentage,
    scatterFilterPercentage,
    setScatterFilterDelta,
    scatterFilterDelta,
    scatterData,
}) => {
    const [isFilterOpen, setIsFilterOpen] = useState(false)

    const handleFilterOpen = () => {
        setIsFilterOpen(true)
    }

    const handleFilterClose = () => {
        setIsFilterOpen(false)
    }

    const handleDeltaChange = (e) => {
        setScatterFilterDelta(Math.max(0, parseInt(e.target.value)))
    }

    const handleMinPercentageChange = (e) => {
        setScatterFilterPercentage(Math.max(0, parseInt(e.target.value)))
    }

    return (
        <Card>
            <CardContent style={{ position: 'relative' }}>
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                    }}
                >
                    <CardTitle title={'Résilience'} />
                    <IconButton onClick={handleFilterOpen}>
                        <SettingsIcon />
                    </IconButton>
                </div>

                <br />
                {series?.length > 0 ? (
                    <div style={{ position: 'relative' }}>
                        {scatterData?.length == 0 ? (
                            <div
                                style={{
                                    textAlign: 'center',
                                    marginTop: 20,
                                    position: 'absolute',
                                    top: '40%',
                                    zIndex: 100,
                                    left: '40%',
                                }}
                            >
                                <Alert severity="info">Pas de données à afficher</Alert>
                            </div>
                        ) : null}
                        <LineChartComponent
                            toggle={false}
                            series={series}
                            exporting={false}
                            zoomBySingleTouch={true}
                            tooltip={{
                                formatter: function () {
                                    return `${this.point.name || ''} (${this.point.x}°C, ${this.point.y}°C)`
                                },
                            }}
                            plotOptions={{
                                column: {
                                    stacking: 'normal',
                                    pointWidth: 40,
                                    borderRadius: 2,
                                },
                                series: {
                                    animation: false,
                                },
                                areaspline: {
                                    fillOpacity: 0.4,
                                },
                                chart: {
                                    margin: 0,
                                },
                                states: {
                                    hover: {
                                        enabled: false,
                                    },
                                },
                                scatter: {
                                    point: {
                                        events: {
                                            click: function () {
                                                setSelectedZone(this.id)
                                            },
                                        },
                                    },
                                },
                            }}
                            type="scatter"
                            yAxis={{
                                title: {
                                    text: 'Température mesurée en ON (°C)',
                                },
                            }}
                            xAxis={{ title: { text: 'Température demandée (°C)' } }}
                        />
                    </div>
                ) : null}

                {/* Filter Settings Dialog */}
                <Dialog open={isFilterOpen} onClose={handleFilterClose}>
                    {/* <DialogTitle>Filtres</DialogTitle> */}
                    <DialogContent>
                        <TextField
                            label="Marge d'erreur (°C)"
                            type="number"
                            value={scatterFilterDelta}
                            onChange={handleDeltaChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Min. % allumées"
                            type="number"
                            value={scatterFilterPercentage}
                            onChange={handleMinPercentageChange}
                            fullWidth
                            margin="normal"
                        />
                        {/* <Button
                            onClick={handleApplyFilter}
                            color="primary"
                            variant="contained"
                            style={{ marginTop: 20 }}
                        >
                            Apply Filter
                        </Button> */}
                    </DialogContent>
                </Dialog>
            </CardContent>
        </Card>
    )
}
