import DashboardIcon from '@mui/icons-material/Dashboard'

type Icon = typeof DashboardIcon

export enum RoutePaths {
    Sites = '/sites',
    Dashboard = '/dashboard', // moved to site routes
    Realtime = '/realtime', // moved to site routes
    Constraints = '/constraints', // moved to site routes
    Maintenance = '/maintenance', // moved to site routes
    Documents = '/documents',
    Help = '/help',
    Contact = '/contact',
    Settings = '/settings/security',
    Groups = '/hidden',
}

export type RouteData = {
    title: string
    navbarTitle: string
    minScope?: 'root' | 'site' | 'object'
    titleLink?: boolean
    path: RoutePaths
    admin?: boolean
    dev?: boolean
    enabled: string[]
    access: string[]
    accessKey?: string[]
    whitelist?: string[]
    Icon: Icon
}
