export enum PermissionSection {
    DASHBOARD_GROUP = 'DashboardGroupView',
    DASHBOARD_SITE = 'DashboardSiteView',
    REALTIME = 'RealtimeView',
    CONSTRAINTS = 'ConstraintsView',
    DOCUMENTS = 'DocumentsView',
}

export enum PermissionType {
    READ = 'Read',
    EDIT = 'Edit',
    MANAGE = 'Manage',
}

export type UserPermission = {
    grantType: PermissionType
    hash: string
    section: PermissionSection
    siteId: string
    userId: number
    userPermissionId: string
}
