import React, { VFC } from 'react'
import { SiteTypeIds } from '@/types/siteTypes'
import HotelIcon from '@mui/icons-material/Hotel'
import ApartmentIcon from '@mui/icons-material/Apartment'
import BusinessIcon from '@mui/icons-material/Business'
import SchoolIcon from '@mui/icons-material/School'
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore'

interface SiteTypeIconProps extends React.ComponentProps<typeof HotelIcon> {
    siteTypeId: SiteTypeIds
}

const SiteTypeIcon: VFC<SiteTypeIconProps> = ({ siteTypeId, ...iconProps }) => {
    switch (siteTypeId) {
        case SiteTypeIds.HOTEL:
        case SiteTypeIds.HOTEL_RESIDENCE:
            return <HotelIcon {...iconProps} />
        case SiteTypeIds.OFFICE:
        case SiteTypeIds.COWORKING_SPACE:
            return <BusinessIcon {...iconProps} />
        case SiteTypeIds.PRIMARY_SCHOOL:
        case SiteTypeIds.UNIVERSITY:
            return <SchoolIcon {...iconProps} />
        case SiteTypeIds.SUPERMARKET:
            return <LocalGroceryStoreIcon {...iconProps} />
        default:
            return <ApartmentIcon {...iconProps} />
    }
}

export default SiteTypeIcon
