import SettingsIcon from '@mui/icons-material/Settings'
import { Chip, CircularProgress } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import moment from 'moment'
import * as React from 'react'
import useLocale from '@/utils/locale/locale.hook'
import { getSignedUrl } from '@/services/s3.service'

export default function BasicTable({ documents, setSelectedDocument, editable, documentLoader }) {
    const locale = useLocale()
    const color = (documentType) => {
        //["doe", "plan", "edf", "enedis", "report", "invoice", "onboarding", "contract"]
        switch (documentType) {
            case 'doe':
                return '#e1bee7'
            case 'plan':
                return '#f8bbd0'
            case 'edf':
                return '#ffcdd2'
            case 'enedis':
                return '#d1c4e9'
            case 'report':
                return '#c5cae9'
            case 'invoice':
                return '#bbdefb'
            case 'onboarding':
                return '#b3e5fc'
            case 'contract':
                return '#b2dfdb'
            default:
                return '#03a9f4'
        }
    }

    const handleClick = async (e, key) => {
        e.preventDefault() // Prevents the default anchor behavior
        const res = await getSignedUrl(key, null)
        if (res?.url) window.open(res?.url, '_blank', 'noopener,noreferrer') // Opens the signed URL in a new tab
    }
    return (
        <TableContainer>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Ajout</TableCell>
                        <TableCell>Dernière édition</TableCell>
                        <TableCell>Document</TableCell>
                        <TableCell></TableCell>
                        {editable && <TableCell></TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {documents?.length === 0 && documentLoader ? (
                        <div style={{ padding: 5 }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        documents.map((row, index) => (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    {moment(row.CreatedAt).format('DD-MM-YYYY')}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {moment(row.UpdatedAt).format('DD-MM-YYYY')}
                                </TableCell>
                                <TableCell>
                                    <a href="#" style={{ color: '#03a9f4' }} onClick={(e) => handleClick(e, row.key)}>
                                        {row?.DocumentType === 'report'
                                            ? `${moment(row.CreatedAt).format('DD-MM-YYYY')}_reporting.pdf`
                                            : row.Name}
                                    </a>
                                </TableCell>
                                <TableCell>
                                    <Chip
                                        label={locale?.documentTypes?.[row?.DocumentType]}
                                        style={{ background: color(row?.DocumentType) }}
                                    />
                                </TableCell>
                                {editable && row?.DocumentType !== 'report' && (
                                    <TableCell align="right">
                                        <SettingsIcon
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => setSelectedDocument(row)}
                                        />
                                    </TableCell>
                                )}
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
