import { useEffect, useState } from 'react'
import { getModeHistory } from '@/services/realtime.service'
import { Card, CardContent, Chip, Avatar } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import AutoModeSvg from '@/assets/img/realtime/auto-mode.svg'
import CoolModeSvg from '@/assets/img/realtime/cool-mode.svg'
import DryModeSvg from '@/assets/img/realtime/dry-mode.svg'
import FanModeSvg from '@/assets/img/realtime/fan-mode.svg'
import HeatModeSvg from '@/assets/img/realtime/heat-mode.svg'
import moment from 'moment'
import { CardTitle } from './card-title.component'

const columns = [
    {
        field: 'DateTime',
        headerName: 'Date',
        width: 200,
        renderCell: (params) => {
            return moment(params?.value).format('DD/MM/YYYY HH:mm')
        },
    },
    {
        field: 'Value',
        headerName: 'Mode',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => {
            let mode =
                params?.value == 2
                    ? 'HEAT'
                    : params?.value == 1
                      ? 'COOL'
                      : params?.value == 3
                        ? 'DRY'
                        : params?.value == 4
                          ? 'FAN'
                          : 'AUTO'
            let backgroundColor =
                mode == 'HEAT'
                    ? '#ff8a80'
                    : mode == 'COOL'
                      ? '#2196f3'
                      : mode == 'DRY'
                        ? '#ff8a80'
                        : mode == 'FAN'
                          ? '#A1E8A1'
                          : '#cfd8dc'
            let image =
                mode == 'HEAT'
                    ? HeatModeSvg
                    : mode == 'COOL'
                      ? CoolModeSvg
                      : mode == 'DRY'
                        ? DryModeSvg
                        : mode == 'FAN'
                          ? FanModeSvg
                          : AutoModeSvg

            return <Chip style={{ backgroundColor: backgroundColor, color: 'white' }} label={mode} />
        },
    },
]

export const ModeHistoryComponent = ({ hash, objectId }) => {
    let [modes, setModes] = useState([])

    useEffect(() => {
        if (hash && objectId) {
            init()
        }
    }, [hash, objectId])

    const init = async () => {
        setModes([])
        let res = await getModeHistory(hash, objectId)
        if (res?.message?.modeHistory?.length > 0) {
            let _modes = removeConsecutiveDuplicates(res?.message?.modeHistory)
            // sort based on the DateTime column in descending order
            _modes.sort((a, b) => new Date(b.DateTime) - new Date(a.DateTime))

            setModes(_modes)
        }
    }

    function removeConsecutiveDuplicates(arr) {
        let lastUnique = null

        return arr.filter((obj) => {
            // Check if the current object is different from the last unique object based on `Value` and `SensorId`
            if (!lastUnique || lastUnique.Value !== obj.Value || lastUnique.SensorId !== obj.SensorId) {
                // Update last unique object and include this object in the final array
                lastUnique = obj
                return true
            }
            // If the current object is a duplicate, skip it
            return false
        })
    }

    if (!modes) return null
    else {
        return (
            <Card>
                <CardContent>
                    <CardTitle title={'Historique des modes'} />
                    <DataGrid
                        rows={modes.map((el) => {
                            return { ...el, id: el?.DataMeasurementId }
                        })}
                        columns={columns}
                        pageSize={5}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                        }}
                        pageSizeOptions={[5, 10]}
                        rowsPerPageOptions={[5, 10]}
                        // components={{ Toolbar: CustomToolbar }}
                        localeText={{
                            toolbarExport: 'Exporter',
                            toolbarExportCSV: 'Au format CSV',
                            toolbarExportPrint: 'Imprimer',
                            labelRowsPerPage: 'Nombres de lignes par page',
                            columnMenuSortAsc: 'ASC',
                            columnMenuSortDesc: 'DESC',
                            columnMenuFilter: 'Filtre',
                            columnMenuShowColumns: 'Montrer colonne',
                            columnMenuHideColumn: 'Cacher',
                            columnMenuUnsort: 'Détrier',
                            MuiTablePagination: {
                                labelRowsPerPage: 'Nombres de lignes par page',
                            },
                        }}
                        sx={{
                            border: 0,
                        }}
                        autoHeight={true}
                        // onCellClick={onCellClick}
                    />
                </CardContent>
            </Card>
        )
    }
}
