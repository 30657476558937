import { VFC } from 'react'
import { Chip, ChipProps } from '@mui/material'
import { SiteTypeIds, SiteTypes } from '@/types/siteTypes'
import SiteTypeIcon from '@/components/Basic/SiteTypeIcon'
import { useTranslation } from 'react-i18next'

interface SiteTypeChipProps extends Omit<ChipProps, 'label' | 'icon'> {
    siteTypeId: SiteTypeIds
    siteType: SiteTypes
}

const SiteTypeChip: VFC<SiteTypeChipProps> = ({ siteTypeId, siteType, ...props }) => {
    const { t } = useTranslation('common')
    return <Chip {...props} icon={<SiteTypeIcon siteTypeId={siteTypeId} />} label={t(`siteTypes.${siteType}`)} />
}

export default SiteTypeChip
