import { get, post } from './utils.service'

export const getS3Url = async (type, folder) => {
    const res = await get(`/s3/url?type=${type}&folder=${folder || 'buildings'}`)
    return await res.json()
}

export const uploadFile = async (file, folder) => {
    let type = file?.type || file?.Type
    console.log('uploadFile type', file)

    const { url } = await getS3Url(type, folder)
    if (!url) return null
    const headersContent = {
        'Content-Type': file?.type,
        Accept: 'application/json',
    }
    const headers = new Headers(headersContent)
    const response = await fetch(url, {
        method: 'PUT',
        body: file,
        headers,
    })

    if (!response) return null
    let resUrl = new URL(response.url)

    return resUrl.protocol + '//' + resUrl.host + resUrl.pathname
}

export const getSignedUrl = async (key, bucket) => {
    const res = await post(`/s3/getSignedUrl`, {
        key,
        bucket,
    })
    return await res.json()
}
