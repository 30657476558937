import React, { useEffect } from 'react'
import { useQuill } from 'react-quilljs'
import 'quill/dist/quill.snow.css'

export const EnhancedNote = ({ content, onContentChange }) => {
    const { quill, quillRef } = useQuill()

    useEffect(() => {
        if (quill) {
            // Set the content when quill initializes
            quill.clipboard.dangerouslyPasteHTML(content)

            // Listen for text-change events
            quill.on('text-change', () => {
                onContentChange(quill.root.innerHTML) // Pass the HTML content to the parent
            })
        }
    }, [quill])

    return (
        <div style={{ width: '100%', height: 300 }}>
            <div ref={quillRef} />
        </div>
    )
}
