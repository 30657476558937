import { ReactNode } from 'react'
import { useMatch, NavLink } from 'react-router-dom'
import { Link } from '@mui/material'

interface NavLinkProps {
    title: ReactNode
    path: string
}

const NavLinkComponent = ({ title, path }: NavLinkProps) => {
    const match = useMatch(path)
    return (
        <Link
            component={NavLink}
            to={path}
            sx={{
                py: 0.5,
                px: 1,
                color: 'inherit',
                textDecoration: match ? 'underline' : 'none',
                fontWeight: match ? 'bold' : 'normal',
                backgroundColor: match ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                borderRadius: '4px',
                transition: 'background-color 0.1s ease-in-out',
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                },
            }}
        >
            {title}
        </Link>
    )
}

export default NavLinkComponent
