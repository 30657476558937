import React, { useState } from 'react'
// nodejs library that concatenates classes
// reactstrap components
import CloseIcon from '@mui/icons-material/Close'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {
    Alert,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Table,
} from '@mui/material'
import { TitleComponent } from '@/components/Basic/library'
import useLocale from '@/utils/locale/locale.hook'

export const GroupComponent = ({
    currentGroup,
    groupDialog,
    removeGroup,
    groups,
    setCurrentGroup,
    setGroupDialog,
    updateGroup,
    rawObjects,
    updateCurrentGroup,
}) => {
    const locale = useLocale()
    const [modal, setModal] = useState(false)

    return (
        <>
            <Alert severity="info">Groupez vos zones pour simplifier le passage de commandes</Alert>
            <Card
                style={{
                    maxWidth: '100%',
                    position: 'sticky',
                    top: 100,
                    marginTop: 10,
                }}
            >
                <CardContent>
                    <TitleComponent title={'Groupes'} style={{ fontSize: 15 }} />
                    {/* <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {currentGroup?.name && !groupDialog ? (
              // <DeleteForeverIcon
              //   style={{ cursor: "pointer", color: "red" }}
              //   onClick={removeGroup}
              // />
              <div
                style={{
                  cursor: "pointer",
                  color: "red",
                  fontSize: 14,
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={removeGroup}
              >
                <DeleteForeverIcon style={{ marginRight: 4 }} />
                {locale?.["constraints"]?.["deleteGroup"]}
              </div>
            ) : null}
            {currentGroup?.name && !groupDialog ? (
              // <DeleteForeverIcon
              //   style={{ cursor: "pointer", color: "red" }}
              //   onClick={removeGroup}
              // />
              <div
                style={{
                  cursor: "pointer",
                  fontSize: 14,
                  color: "#424242",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  setCurrentGroup({ name: null, objects: [] });
                }}
              >
                <CloseIcon style={{ marginRight: 4 }} />
                {locale?.["constraints"]?.["unselect"]}
              </div>
            ) : null}
          </div> */}
                    <Grid container spacing={2} style={{ marginTop: 10 }}>
                        {/* {currentGroup?.name && !groupDialog && (
              <Grid item md={12}>
                <div
                  style={{
                    cursor: "pointer",
                    fontSize: 14,
                    color: "#424242",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setCurrentGroup({ name: null, objects: [] });
                  }}
                >
                  <CloseIcon style={{ marginRight: 4 }} />
                  {locale?.["constraints"]?.["unselect"]}
                </div>
              </Grid>
            )} */}
                        <Grid item md={12}>
                            <FormControl fullWidth>
                                <InputLabel id="groups-select-label" style={{ background: 'white', padding: 2 }}>
                                    {locale?.['constraints']?.['myGroups']}
                                </InputLabel>
                                <Select
                                    labelId="groups-select-label"
                                    id="groups-select"
                                    value={groups.find((el) => el.name == currentGroup?.name)?.name || ''}
                                    onChange={(e) => {
                                        let find = groups.find((el) => el.name == e.target.value)
                                        if (find) {
                                            setCurrentGroup(find)
                                        }
                                    }}
                                    disabled={
                                        groups.length === 0 ||
                                        (!currentGroup?.name && currentGroup?.objects?.length > 0)
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <CloseIcon
                                                onClick={() => {
                                                    setCurrentGroup({ name: null, objects: [] })
                                                }}
                                                sx={{
                                                    visibility:
                                                        currentGroup?.name && !groupDialog ? 'visible' : 'hidden',
                                                    cursor: 'pointer',
                                                    marginRight: 2,
                                                }}
                                            />
                                        </InputAdornment>
                                    }
                                >
                                    {groups.map((el) => {
                                        return (
                                            <MenuItem value={el.name} key={el.name}>
                                                {el.name}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={12}>
                            <Button
                                disabled={currentGroup?.objects?.length < 2}
                                variant="contained"
                                onClick={() => {
                                    if (currentGroup?.name) {
                                        updateGroup()
                                    } else setGroupDialog(true)
                                }}
                                style={{ marginRight: 10 }}
                                fullWidth
                            >
                                {currentGroup?.name ? 'Enregistrer' : locale?.['constraints']?.['group']}
                            </Button>
                        </Grid>
                        {currentGroup?.name && !groupDialog && (
                            <Grid item md={12} alignItems="center">
                                <div
                                    style={{
                                        cursor: 'pointer',
                                        color: 'red',
                                        fontSize: 14,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    onClick={removeGroup}
                                >
                                    <DeleteForeverIcon style={{ marginRight: 4 }} />
                                    {locale?.['constraints']?.['deleteGroup']}
                                </div>
                            </Grid>
                        )}
                    </Grid>
                    {currentGroup?.objects?.length > 0 && (
                        <>
                            <Divider style={{ marginTop: 25, marginBottom: 10 }} />
                            <Table>
                                {currentGroup?.objects?.map((el) => {
                                    let find = rawObjects?.find((obj) => obj.ObjectId == el)
                                    return (
                                        <Chip
                                            key={el}
                                            onDelete={() => updateCurrentGroup(el)}
                                            label={find?.Name}
                                            style={{ margin: 2, fontSize: 9 }}
                                        />
                                    )
                                })}
                            </Table>
                        </>
                    )}
                </CardContent>
            </Card>
        </>
    )
}
