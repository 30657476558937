import { FC } from 'react'
import { useSite } from '../useSite'
import SiteDetails from './details.component'
import { SiteYearlyConsumptionsBarChart } from '../dashboard/components/energyConsumptions/YearlyConsumptionsBarChart'
import { Grid, Paper, Container } from '@mui/material'
import SiteHeader from './SiteHeader'
import EnergySummary from './EnergySummary'

const SiteHome: FC = () => {
    const { site } = useSite()
    return (
        <Paper>
            <SiteHeader site={site} />
            <EnergySummary siteHash={site.hash} />
            {/* <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                <Grid item xs={12} md={4}>
                    <SiteDetails site={site} />
                </Grid>
                <Grid item xs={12} md={8}>
                    <SiteYearlyConsumptionsBarChart siteHash={site.hash} />
                </Grid>
            </Grid> */}
        </Paper>
    )
}

export default SiteHome
