/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useState } from 'react'
import { Box, CssBaseline, Toolbar, useMediaQuery, useTheme } from '@mui/material'
import { Outlet } from 'react-router-dom'
import Header from './header.component'
import NavDrawer, { useEnableNavDrawer } from './navDrawer.component'
import Footer from './footer.component'
import { PERMANENT_DRAWER_BREAKPOINT, DRAWER_WIDTH } from './constants'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '../fallbacks/error.component'
import { RoutePaths } from '@/navigation/route'

// Inspired from https://mui.com/material-ui/react-drawer/#responsive-drawer

const GENERAL_LINKS = [
    { title: 'Aide', path: RoutePaths.Help },
    { title: 'Contact', path: RoutePaths.Contact },
]

const MainLayout: FC = () => {
    const [mobileOpen, setMobileOpen] = useState(false)
    const theme = useTheme()
    const isPermanent = useMediaQuery(theme.breakpoints.up(PERMANENT_DRAWER_BREAKPOINT))
    const enableNavDrawer = useEnableNavDrawer()

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Header
                    handleDrawerToggle={enableNavDrawer ? () => setMobileOpen(true) : undefined}
                    links={GENERAL_LINKS}
                />
                <NavDrawer
                    variant={isPermanent ? 'permanent' : 'temporary'}
                    onClose={() => setMobileOpen(false)}
                    open={isPermanent || mobileOpen}
                />
                <Box
                    component={'main'}
                    sx={{
                        flexGrow: 1,
                        py: { xs: 5, md: 3 },
                        px: { xs: 0, sm: 1, md: 2, lg: 3 },
                        width: { [PERMANENT_DRAWER_BREAKPOINT]: `calc(100% - ${DRAWER_WIDTH}px)` },
                        minHeight: '100vh',
                    }}
                >
                    <Toolbar />
                    <ErrorBoundary fallback={<ErrorFallback />}>
                        <Outlet />
                    </ErrorBoundary>
                </Box>
            </Box>
            <Footer links={GENERAL_LINKS} />
        </>
    )
}

export default MainLayout
