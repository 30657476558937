import { FC, createContext, useContext } from 'react'
import { useParams } from 'react-router-dom'
import useAuth from '@/context/useAuth'
import { SiteMetaData } from '@/types/siteMetadata'
import { NotFoundError } from '@/utils/error.utils'
import { getObjectsForSite } from '@/services/realtime.service'
import { ObjectMetadata } from '@/types/objectMetadata'
import uniqBy from 'lodash/uniqBy'
import { camelCaseDeep } from '@/utils/camelCaseDeep'
import { useQuery, UseQueryResult } from '@tanstack/react-query'

interface SiteContextType {
    site: SiteMetaData
    objectsQuery: UseQueryResult<ObjectMetadata[]>
    object?: ObjectMetadata | null
}

export const SiteContext = createContext<SiteContextType>({
    site: {} as SiteMetaData,
    objectsQuery: {} as UseQueryResult<ObjectMetadata[]>,
})

export const useSite = () => {
    const context = useContext(SiteContext)
    if (!context) {
        throw new Error('useSite must be used within a SiteProvider')
    }
    return context
}

const getObject = (
    objects: ObjectMetadata[] | undefined,
    objectId?: string | number
): ObjectMetadata | undefined | null => {
    if (!objects || !objectId) {
        return undefined // no data
    }
    const match = objects.find((object) => object.objectId === Number(objectId))
    return match || null
}

export const SiteContextProvider: FC = ({ children }) => {
    const { siteHash, objectId, ...others } = useParams()
    const { me } = useAuth()
    const site = me?.sites?.find((site) => site.hash === siteHash)
    const objectsQuery = useQuery<ObjectMetadata[]>({
        queryKey: ['objects-for-site', siteHash],
        queryFn: async () => {
            const response = await getObjectsForSite({ hash: siteHash })
            const rawObjects = response.objects || []
            const uniqueObjects = uniqBy(rawObjects, 'ObjectId')
            return uniqueObjects.map(camelCaseDeep) as unknown as ObjectMetadata[]
        },
        staleTime: 1000 * 60 * 10,
        enabled: !!site,
    })
    if (!site) {
        throw new NotFoundError('Site not found')
    }
    const object = getObject(objectsQuery.data, objectId || others['*']) // * is for Constraints page

    return <SiteContext.Provider value={{ site, objectsQuery, object }}>{children}</SiteContext.Provider>
}
