import { VFC } from 'react'
import { Outlet } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import SiteDashboardFallback from '../dashboard//fallback'
import { RealtimeProvider, useRealtime } from './useRealtime'
import SiteHeader from '../siteHeader.component'
import { useTranslation } from 'react-i18next'

const RealtimeComponent: VFC = () => {
    const { t } = useTranslation('site')
    const { clearModifications } = useRealtime() as { clearModifications: () => void }
    return (
        <>
            <SiteHeader title={t('navLinks.realtime')} onBack={clearModifications} />
            <Outlet />
        </>
    )
}

const RealtimeLayout: VFC = () => (
    <ErrorBoundary fallbackRender={SiteDashboardFallback}>
        <RealtimeProvider>
            <RealtimeComponent />
        </RealtimeProvider>
    </ErrorBoundary>
)

export default RealtimeLayout
