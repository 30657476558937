/* eslint-disable no-unsafe-optional-chaining */
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/Info'
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    Fab,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CardModal } from '@/components/Global/card-modal.component'
import { fetchBuildings } from '@/services/site.service'
import { makeid } from '@/utils/global.utils'
import { useSite } from '../../useSite'

export const Tab3Component = ({ updateBuildings, _setControllers, display }) => {
    const { site } = useSite()
    const [buildings, setBuildings] = useState([])
    const [controllers, setControllers] = useState([])
    useEffect(() => {
        init()
    }, [site])

    const init = async () => {
        setBuildings([])
        setControllers([])
        if (site.hash) {
            let res = await fetchBuildings(site.hash)
            console.log(res)
            if (res?.controllers) {
                let _controllers = res?.controllers?.filter((el) => el.ipAddress)

                setControllers(_controllers)
                _setControllers(_controllers)
            }

            if (res?.buildings) {
                let mapped = res?.buildings?.map((el) => {
                    return {
                        ...el,
                        hash: el?.ObjectHash,
                        name: el?.Name,
                        ip_addresses: res?.controllers
                            ?.filter((item) => item?.building?.ObjectId == el?.ObjectId)
                            .map((item) => item.ipAddress),
                    }
                })
                setBuildings(mapped)
            }
        }
    }

    let [selectedBuilding, setSelectedBuilding] = useState({
        ip_addresses: [],
        name: null,
    })
    const [modal, setModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const addBuilding = () => {
        let find = buildings?.find((el) => el?.hash === selectedBuilding?.hash)
        let newBuildings = [...buildings]
        if (!find) {
            newBuildings.push({
                ...selectedBuilding,
                hash: makeid(),
            })
        } else {
            newBuildings = newBuildings.map((el) => {
                if (el?.hash === selectedBuilding?.hash) {
                    return { ...el, ...selectedBuilding }
                } else return el
            })
        }
        updateBuildings(newBuildings)
        setBuildings(newBuildings)
    }
    const verifyAvailableIps = (ip) => {
        let out = true
        if (ip) {
            for (let i = 0; i < buildings?.length; i++) {
                if (buildings?.[i]?.ip_addresses?.indexOf(ip) > -1 && buildings?.[i]?.hash !== selectedBuilding?.hash)
                    return false
            }
        }
        return out
    }
    console.log('controllers', controllers)
    if (controllers?.length <= 1 || !display) return null
    return (
        <>
            <Modal
                open={editModal}
                onClose={() => setEditModal(false)}
                aria-labelledby="modal-modal-title-edit"
                aria-describedby="modal-modal-description-edit"
            >
                <Card
                    sx={{
                        position: 'absolute',
                        top: { md: '50%', xs: 40 },
                        left: { md: '50%', xs: 4 },
                        right: { md: '', xs: 4 },
                        transform: { md: 'translate(-50%, -50%)', xs: '' },
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                        <TextField
                            label="Nom du bâtiment"
                            style={{ width: '100%' }}
                            value={selectedBuilding?.name || ''}
                            onChange={(e) => {
                                let name = e.target.value
                                setSelectedBuilding({ ...selectedBuilding, name: name })
                            }}
                        />
                        <Typography id="modal-modal-title" style={{ marginTop: 20, marginBottom: 10 }}>
                            Sélectionner les adresses IP associées
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                            }}
                        >
                            {controllers
                                ?.filter((el) => el?.ipAddress)
                                ?.map((el) => {
                                    return (
                                        <FormControlLabel
                                            key={el?.ipAddress}
                                            control={
                                                <Checkbox
                                                    j
                                                    disabled={!verifyAvailableIps(el?.ipAddress)}
                                                    checked={
                                                        selectedBuilding?.ip_addresses?.indexOf(el?.ipAddress) > -1
                                                    }
                                                    onChange={() => {
                                                        let find =
                                                            selectedBuilding?.ip_addresses?.indexOf(el?.ipAddress) > -1
                                                        if (find) {
                                                            setSelectedBuilding({
                                                                ...selectedBuilding,
                                                                ip_addresses: selectedBuilding?.ip_addresses?.filter(
                                                                    (item) => el?.ipAddress != item
                                                                ),
                                                            })
                                                        } else {
                                                            setSelectedBuilding({
                                                                ...selectedBuilding,
                                                                ip_addresses: [
                                                                    ...selectedBuilding?.ip_addresses,
                                                                    el?.ipAddress,
                                                                ],
                                                            })
                                                        }
                                                    }}
                                                    name={el?.ipAddress}
                                                    color="primary"
                                                />
                                            }
                                            label={el?.ipAddress || ''}
                                        />
                                    )
                                })}
                        </Box>
                        <Button
                            variant="contained"
                            onClick={() => {
                                addBuilding()
                                setEditModal(false)
                            }}
                            disabled={!selectedBuilding?.name || selectedBuilding?.ip_addresses?.length === 0}
                        >
                            {selectedBuilding?.edit ? 'Editer' : ' Ajouter un bâtiment'}
                        </Button>
                    </CardContent>
                </Card>
            </Modal>
            <CardModal
                modal={modal}
                setModal={setModal}
                content={
                    <>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Définissez vos bâtiments
                        </Typography>
                        <br />
                        <Alert severity="info">
                            Nous avons remarqué que <b>plusieurs GTC</b> étaient présentes sur votre réseau. Cela peut
                            être dû au fait que vous avez plusieurs bâtiments sur un unique réseau informatique. Vous
                            pouvez définir vos bâtiments en y associant une ou plusieurs machines.
                        </Alert>
                    </>
                }
            />

            <Card sx={{ position: 'relative', width: '100%', marginTop: '30px' }}>
                <CardContent>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <FormControl
                            style={{
                                flex: 1,
                                marginRight: 10,
                                maxWidth: 300,
                                position: 'relative',
                            }}
                        >
                            <InfoIcon
                                style={{
                                    color: '#2196f3',
                                    position: 'absolute',
                                    right: -24,
                                    top: 0,
                                    cursor: 'pointer',
                                }}
                                onClick={() => setModal(true)}
                            />
                            <InputLabel id="groups-select-label" style={{ background: 'white', padding: 2 }}>
                                Mes bâtiments
                            </InputLabel>
                            <Select
                                labelId="buildings-select-label"
                                id="buildings-select"
                                value={buildings?.find((el) => el.hash == selectedBuilding?.hash)?.hash || ''}
                                onChange={(e) => {
                                    let find = buildings?.find((el) => el.hash == e.target.value)
                                    if (find) {
                                        setSelectedBuilding({ ...find, edit: true })
                                    }
                                }}
                                disabled={buildings?.length === 0 || !buildings}
                            >
                                {buildings?.map((el) => {
                                    return (
                                        <MenuItem key={el.hash} value={el.hash}>
                                            {el.name}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        {/* <Button
                style={{ marginLeft: 10, height: 56 }}
                variant="contained"
                onClick={() => setEditModal(true)}
                disabled={
                  buildings?.length === 0 || selectedBuilding?.edit === false
                }
              >
                Editer
              </Button> */}
                        <Fab
                            color="primary"
                            aria-label="add"
                            size="small"
                            onClick={() => {
                                if (!selectedBuilding?.name) {
                                    setSelectedBuilding({
                                        name: null,
                                        ip_addresses: [],
                                        hash: makeid(),
                                        edit: false,
                                    })
                                }
                                setEditModal(true)
                            }}
                        >
                            {selectedBuilding?.name ? <EditIcon /> : <AddIcon />}
                        </Fab>
                    </div>
                </CardContent>
            </Card>
        </>
    )
}
