import { VFC, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Card, CardMedia, CardContent, Typography, Divider } from '@mui/material'
import { SiteMetaData } from '@/types/siteMetadata'
import { useTranslation } from 'react-i18next'
import BuildingImg from '@/assets/img/icons/common/building.png'

interface SiteCardProps {
    title: ReactNode
    to: string
    site: SiteMetaData
}

function formatSurface(surface: SiteMetaData['surface']): ReactNode {
    return (
        <span>
            {new Intl.NumberFormat('defalt', { style: 'unit', unit: 'meter' }).format(surface)}
            <sup>2</sup>
        </span>
    )
}

function formatAddress(address: Pick<SiteMetaData, 'city' | 'country'>): string {
    return [address.city, address.country].filter(Boolean).join(', ')
}

const SiteCardMedia: VFC<{ pictureUrl: string | null; title: string; height?: number }> = ({
    pictureUrl,
    title,
    height = 120,
}) => {
    if (pictureUrl) {
        return <CardMedia sx={{ height }} image={pictureUrl} title={title} />
    }
    return (
        <CardMedia
            sx={{
                height,
                backgroundColor: 'grey.200',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            title={title}
        >
            <img
                src={BuildingImg}
                style={{ height: (height * 2) / 3 }}
                alt="Town icons created by Zlatko Najdenovski - Flaticon"
            />
        </CardMedia>
    )
}

const SiteCard: VFC<SiteCardProps> = ({ title, to, site }) => {
    const { t } = useTranslation('common')
    return (
        <Link to={to}>
            <Card sx={{ height: 260 }}>
                <SiteCardMedia pictureUrl={site.pictureUrl} title={site.name} />
                <CardContent>
                    <Typography
                        variant="h6"
                        component="div"
                        gutterBottom
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                            lineClamp: 2,
                            height: 48,
                        }}
                        title={site.name as string}
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}
                    >
                        {/*  eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                        {t(`siteTypes.${site.siteType}`)}
                        <Divider orientation="vertical" flexItem sx={{ my: 0.25 }} />
                        {formatSurface(site.surface)} <Divider orientation="vertical" flexItem sx={{ my: 0.25 }} />{' '}
                        {formatAddress(site)}
                    </Typography>
                </CardContent>
            </Card>
        </Link>
    )
}

export default SiteCard
