import { VFC } from 'react'
import { FallbackProps } from 'react-error-boundary'
import NotFound from '@/components/fallbacks/notFound.component'
import InternalError from '@/components/fallbacks/error.component'
import Forbidden from '@/components/fallbacks/forbidden.component'
import { NotFoundError, ForbiddenError } from '@/utils/error.utils'

const SiteFallback: VFC<FallbackProps> = ({ error }) => {
    if (error instanceof NotFoundError) {
        return <NotFound />
    }
    if (error instanceof ForbiddenError) {
        return <Forbidden />
    }
    return <InternalError />
}

export default SiteFallback
