export class ErrorClass {
    ok = false
    json() {
        return { success: false, crash: true }
    }
}

export class NotFoundError extends Error {
    constructor(message?: string) {
        super(message)
        this.name = 'NotFoundError'
    }
}

export class InternalError extends Error {
    constructor(message?: string) {
        super(message)
        this.name = 'InternalError'
    }
}

export class ForbiddenError extends Error {
    constructor(message?: string) {
        super(message)
        this.name = 'ForbiddenError'
    }
}
