import React, { useEffect, useState } from 'react'
import { CardBody, CardHeader } from 'reactstrap'
import QRcode from '@/assets/img/other/qrcode.png'
import { Alert, Box, Card, Grid } from '@mui/material'
import { TitleComponent } from '@/components/Basic/library'
import { deleteNote, getSiteNotes, getSiteSettings } from '@/services/site.service'
import DocumentModal from './site/document.modal'
import BasicTable from './site/documents.table'
import { ContainerComponent } from '@/components/Global/container.component'
import { isDemo } from '@/services/utils.service'
import { NoteComponent } from './site/notes.component'
import { useSite } from '../useSite'
import useAuth from '@/context/useAuth'
import { useTranslation } from 'react-i18next'

const DocumentsPage = () => {
    const { me } = useAuth()
    const { site } = useSite()
    const { t } = useTranslation('site', { keyPrefix: 'documents' })
    const [documents, setDocuments] = useState([])
    const [notes, setNotes] = useState(null)
    const [selectedDocument, setSelectedDocument] = useState(null)
    const [documentLoader, setDocumentLoader] = useState(false)
    const documentTypes = ['doe', 'plan', 'edf', 'enedis', 'report', 'invoice', 'onboarding', 'contract']

    const init = async () => {
        setDocumentLoader(true)
        let res = await getSiteSettings(site.hash)
        console.log('res', res)
        if (res?.documents) setDocuments(res?.documents)
        res = await getSiteNotes(site.hash)
        if (res?.notes) setNotes(res?.notes)
        setDocumentLoader(false)
    }

    useEffect(() => {
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {selectedDocument && (
                <DocumentModal
                    open={selectedDocument ? true : false}
                    handleClose={() => {
                        setSelectedDocument(null)
                        init()
                    }}
                    selectedDocument={selectedDocument}
                    documents={documents}
                    site={site}
                    documentTypes={documentTypes}
                />
            )}

            <Grid container spacing={2}>
                {site.siteType == 'Hotel' && (
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <TitleComponent title={t('labels.title')} />

                                <a
                                    href={`/pdf-small?hash=${site.hash}`}
                                    target={'_blank'}
                                    style={{ color: '#03a9f4' }}
                                    rel="noreferrer"
                                >
                                    {t('labels.print')}
                                </a>
                            </CardHeader>
                            <CardBody>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: { xs: 'column', md: 'row' },
                                    }}
                                >
                                    <img src={QRcode} />
                                    <div>
                                        <p>{t('labels.description')}</p>
                                        <Alert severity="warning">{t('labels.warning')}</Alert>
                                    </div>
                                </Box>
                            </CardBody>
                        </Card>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Box>
                        <Card>
                            <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <TitleComponent title={t('title')} />

                                {me?.userPermissions?.find(
                                    (permission) =>
                                        permission?.hash === site.hash &&
                                        permission?.section === 'DocumentsView' &&
                                        ['Edit', 'Manage']?.includes(permission?.grantType)
                                ) &&
                                    !isDemo() && (
                                        <div
                                            style={{ color: '#03a9f4', cursor: 'pointer' }}
                                            onClick={() => {
                                                setSelectedDocument({
                                                    DocumentType: documentTypes?.[0],
                                                    Name: '',
                                                })
                                            }}
                                        >
                                            {t('add')}
                                        </div>
                                    )}
                            </CardHeader>
                            <CardBody>
                                <BasicTable
                                    documents={documents?.filter((el) => documentTypes?.indexOf(el.DocumentType) > -1)}
                                    setSelectedDocument={setSelectedDocument}
                                    editable={me?.userPermissions?.find(
                                        (permission) =>
                                            permission?.hash === site.hash &&
                                            permission?.section === 'DocumentsView' &&
                                            ['Edit', 'Manage']?.includes(permission?.grantType)
                                    )}
                                    documentLoader={documentLoader}
                                />
                            </CardBody>
                        </Card>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <NoteComponent
                        notes={notes}
                        addNote={(newNote) => {
                            let exists = notes?.find((el) => el?.MaintenanceNoteId === newNote?.MaintenanceNoteId)
                            if (exists) {
                                let newNotes = notes?.map((el) => {
                                    if (el?.MaintenanceNoteId === newNote?.MaintenanceNoteId) {
                                        return newNote
                                    }
                                    return el
                                })
                                setNotes([...newNotes])
                                return
                            }
                            setNotes([...notes, newNote])
                        }}
                        removeNote={async (note) => {
                            let newNotes = notes?.filter((el) => el?.MaintenanceNoteId !== note?.MaintenanceNoteId)
                            setNotes([...newNotes])
                            await deleteNote(site.hash, note?.MaintenanceNoteId)
                        }}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default DocumentsPage
