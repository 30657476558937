import { VFC, useState, useRef, useEffect } from 'react'
import useAuth from '@/context/useAuth'
import {
    IconButton,
    Box,
    Popper,
    MenuItem,
    MenuList,
    Tooltip,
    TextField,
    ClickAwayListener,
    useMediaQuery,
    Theme,
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { fuzzyMatch } from '@/utils/fuzzyMatch'
import Highlight from '@/components/Global/Highlight'
import SearchIcon from '@mui/icons-material/Search'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { RoutePaths } from '@/navigation/route'

const SiteSwitch: VFC = () => {
    const { me } = useAuth()
    const { t } = useTranslation('site')
    const [search, setSearch] = useState('')
    const searchInputRef = useRef<HTMLInputElement>(null)
    const toggleRef = useRef<HTMLButtonElement>(null)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(toggleRef.current)
    const navigate = useNavigate()
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    const handleOpen = (target: HTMLButtonElement | null) => {
        setAnchorEl(target)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleSelect = (siteHash: string) => {
        navigate(`${RoutePaths.Sites}/${siteHash}`)
        handleClose()
        setSearch('')
    }

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'k' && event.metaKey) {
                event.preventDefault()
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                handleOpen(toggleRef.current)
            }
            if (event.key === 'Escape') {
                handleClose()
            }
        }

        document.addEventListener('keydown', handleKeyDown)

        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    if (!me?.sites || me.sites.length < 2) {
        return null
    }

    const matchedSites = fuzzyMatch(me.sites, 'name', search)

    return (
        <>
            <Tooltip title={t('switchSite')} arrow>
                <IconButton
                    onClick={(e) => handleOpen(e.currentTarget)}
                    sx={{ color: 'grey.300', mt: 0.5, ml: -2 }}
                    size="small"
                    ref={toggleRef}
                >
                    <ArrowDropDownIcon />
                </IconButton>
            </Tooltip>
            <Popper
                anchorEl={toggleRef.current}
                open={Boolean(anchorEl)}
                placement={isMobile ? 'bottom' : 'right-end'}
                sx={(theme) => ({
                    zIndex: theme.zIndex.modal,
                    backgroundColor: theme.palette.background.paper,
                    width: 350,
                    p: 1,
                    borderRadius: theme.spacing(1),
                    border: `1px solid ${theme.palette.divider}`,
                })}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <Box>
                        <TextField
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder={t('search')}
                            variant="standard"
                            fullWidth
                            autoFocus={Boolean(anchorEl)}
                            ref={searchInputRef}
                            sx={{ px: 1 }}
                            InputProps={{
                                startAdornment: (
                                    <SearchIcon sx={{ transform: 'rotateY(180deg)', color: 'text.secondary', mr: 1 }} />
                                ),
                            }}
                        />
                        <MenuList
                            autoFocus={false}
                            sx={{
                                maxHeight: 300,
                                overflowY: 'auto',
                                scrollbarWidth: 'thin',
                            }}
                        >
                            {matchedSites.map(({ item: site, ranges }) => (
                                <MenuItem
                                    key={site.hash}
                                    onClick={() => handleSelect(site.hash)}
                                    sx={{
                                        whiteSpace: 'wrap',
                                    }}
                                >
                                    <Highlight text={site.name} matchedIndexRanges={ranges} />
                                </MenuItem>
                            ))}
                            {matchedSites.length === 0 && (
                                <MenuItem disabled sx={{ whiteSpace: 'wrap', fontStyle: 'italic' }}>
                                    {t('noMatchedSite')}
                                </MenuItem>
                            )}
                        </MenuList>
                    </Box>
                </ClickAwayListener>
            </Popper>
        </>
    )
}

export default SiteSwitch
