import { Card, CardContent } from '@mui/material'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid'
import * as React from 'react'

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    )
}

const columns = [
    {
        field: 'name',
        headerName: 'Nom',
        width: 200,
        renderCell: (params) => {
            let row = params.row
            return <div style={{ cursor: 'pointer', fontWeight: 'bold' }}>{row.name}</div>
        },
    },
    {
        field: 'on',
        headerName: '% ON',
        flex: 1,
        renderCell: (params) => {
            let row = params.row
            return <div>{row.on}%</div>
        },
    },
    {
        field: 'settemp',
        headerName: 'Tem. demandée (°C)',
        flex: 1,
        renderCell: (params) => {
            let row = params.row
            return <div>{row.settemp}°C</div>
        },
    },
    {
        field: 'temp',
        headerName: 'Temp. mesurée (°C)',
        flex: 1,
        renderCell: (params) => {
            let row = params.row
            return <div>{row.temp}°C</div>
        },
    },
    // {
    //     headerName: 'Mode',
    //     field: 'mode',
    //     flex: 1,
    //     renderCell: (params) => {
    //         let row = params.row
    //         let mode =
    //             row.mode === 'COOL' ? (
    //                 <Chip label={row.mode} style={{ color: '#2196f3', background: '#e3f2fd' }} />
    //             ) : row.mode === 'HEAT' ? (
    //                 <Chip label={row.mode} style={{ color: 'white', background: '#ff8a80' }} />
    //             ) : (
    //                 <Chip label={row.mode} style={{ color: 'black', background: '#eeff41' }} />
    //             )
    //         return mode
    //     },
    // },
]

export const EquipmentsTable = ({ rows, setSelectedZone }) => {
    const onCellClick = (params) => {
        if (params?.field == 'name') {
            setSelectedZone(params.id)
        }
    }
    return (
        <Card style={{ width: '100%', overflow: 'scroll' }}>
            <CardContent>
                {/* <CardTitle title={"Détails par équipments"} /> */}
                <DataGrid
                    rows={rows.map((el) => {
                        return { ...el }
                    })}
                    columns={columns}
                    components={{ Toolbar: CustomToolbar }}
                    localeText={{
                        toolbarExport: 'Détails',
                        toolbarExportCSV: 'Au format CSV',
                        toolbarExportPrint: 'Imprimer',
                        labelRowsPerPage: 'Nombres de lignes par page',
                        columnMenuSortAsc: 'ASC',
                        columnMenuSortDesc: 'DESC',
                        columnMenuFilter: 'Filtre',
                        columnMenuShowColumns: 'Montrer colonne',
                        columnMenuHideColumn: 'Cacher',
                        columnMenuUnsort: 'Détrier',
                        MuiTablePagination: {
                            labelRowsPerPage: 'Nombres de lignes par page',
                        },
                    }}
                    sx={{
                        border: 0,
                    }}
                    pageSize={10}
                    autoHeight={true}
                    onCellClick={onCellClick}
                />
            </CardContent>
        </Card>
    )
}
