import { VFC, ReactNode } from 'react'
import { SiteRoutes, matchSiteRoute, getAllowedSiteRoutes } from './siteRoutes'
import { useTranslation } from 'react-i18next'
import { TabProps, Tab, Tabs } from '@mui/material'
import { NavLink, useParams } from 'react-router-dom'
import { RoutePaths } from '@/navigation/route'
import useAuth from '@/context/useAuth'

interface NavTabProps extends TabProps<typeof NavLink> {
    label: ReactNode
    to: string
}

// https://mui.com/material-ui/react-tabs/#nav-tabs
const NavTab: VFC<NavTabProps> = (props) => <Tab component={NavLink} {...props} />

const SiteNavLinks: VFC = () => {
    const { t } = useTranslation('site', { keyPrefix: 'navLinks' })
    const { siteHash } = useParams<{ siteHash: string }>()
    const { me } = useAuth()
    if (!me || !siteHash) {
        return null
    }
    const { userPermissions } = me

    const allowedRoutes = getAllowedSiteRoutes(userPermissions, siteHash)
    const matchedRoute = matchSiteRoute(location.pathname)
    const selectedIndex = allowedRoutes.indexOf(matchedRoute || SiteRoutes.HOME)

    return (
        <Tabs orientation="vertical" value={selectedIndex} sx={{ mt: 2 }}>
            {allowedRoutes.map((href, index) => (
                <NavTab key={href} label={t(href)} to={`${RoutePaths.Sites}/${siteHash}/${href}`} value={index} />
            ))}
        </Tabs>
    )
}

export default SiteNavLinks
