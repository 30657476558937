import { useState } from 'react'
import debounce from 'lodash/debounce'
import { useSearchParams } from 'react-router-dom'

interface UseSearchOptions {
    debounceTimeMs?: number
}

const DEFAULT_DEBOUNCE_TIME_MS = 300
const SEARCH_QUERY_PARAM = 'q'

const useSearch = (
    initialSearch: string = '',
    { debounceTimeMs = DEFAULT_DEBOUNCE_TIME_MS }: UseSearchOptions = {}
) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const search = searchParams.get(SEARCH_QUERY_PARAM) || initialSearch
    const [debouncedSearch, setDebouncedSearch] = useState(search)

    const debouncedSetSearch = debounce((value: string) => {
        setDebouncedSearch(value)
    }, debounceTimeMs)

    const setSearch = (value: string) => {
        setSearchParams((previous) => {
            if (value) {
                previous.set(SEARCH_QUERY_PARAM, value)
            } else {
                previous.delete(SEARCH_QUERY_PARAM)
            }
            return previous
        })
        debouncedSetSearch(value)
    }

    const handleClearSearch = () => {
        setSearch('')
    }

    return { search, setSearch, debouncedSearch, handleClearSearch }
}

export default useSearch
