import { Card, CardContent, Grid } from '@mui/material'
import HighchartsChart from '@/components/Charts/HighchartsChart'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { electricBlue, electricGreen, electricOrange } from '@/components/Basic/colors'
import { hexToRGB } from '@/utils/global.utils'
import { Legend } from '../../../sites/site.page'
import { CardTitle } from '../components/card-title.component'

export const TemperatureChart = ({ data }) => {
    const { width } = useSelector((state) => state.globalStore)
    // const chartComponentRef = useRef(null);
    const [series, setSeries] = useState([])
    const [labels, setLabels] = useState([])
    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'spline',
        },
        legend: { enabled: false },
        title: {
            text: '',
            enabled: false,
        },
        yAxis: [
            {
                labels: {
                    format: '{value}°C',
                },
                title: {
                    text: 'Temperature',
                },
                opposite: true,
            },
            {
                // Secondary yAxis
                gridLineWidth: 0,
                title: {
                    text: 'ON (%)',
                },
                max: 100,
                labels: {
                    format: '{value} %',
                },
            },
        ],
        xAxis: [
            {
                categories: [],
                crosshair: true,
                type: 'datetime',
            },
        ],
        series: [],
        exporting: {
            enabled: true,
        },
    })
    const [updating, setUpdating] = useState(false)
    const [chartKey, setChartKey] = useState(Date.now())

    useEffect(() => {
        setUpdating(true)
        let _series = [
            {
                type: 'column',
                name: 'On (%)',
                hash: 'On (%)',
                data: data?.map((el) => {
                    if (el?.Zone_OnPercentage !== undefined) return parseInt(el?.Zone_OnPercentage * 10) / 10
                    else return null
                }),
                yAxis: 1,
                visible: true,
                color: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                    stops: [
                        [0, hexToRGB(electricBlue, 0.7)], // Start color
                        [1, hexToRGB(electricBlue, 0.1)], // End color
                    ],
                },
                // color: {
                //   linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                //   stops: [
                //     [0, hexToRGB(electricBlue, 0.5)],
                //     [1, hexToRGB(electricBlue, 0.1)],
                //   ],
                // },
                // color: electricBlue,
                opacity: 0.8,
                pointWidth: 15,
                borderRadiusTop: 10,
            },
            {
                type: 'spline',
                name: 'Temp. Int (°C)',
                hash: 'Moy.',
                data: data?.map((el) => {
                    if (el?.tInt !== undefined) return parseInt(el?.tInt * 10) / 10
                    else return el?.tInt
                }),
                yAxis: 0,
                visible: true,
                color: electricBlue,
                dashStyle: 'DashDot',
                showLegend: true,
            },
            {
                type: 'spline',
                name: 'Temp. Extérieure (°C)',
                hash: 'tExt',
                data: data?.map((el) => {
                    if (el?.tExt !== undefined) return parseInt(el?.tExt * 10) / 10
                    else return el?.tExt
                }),
                yAxis: 0,
                visible: true,
                color: electricOrange,
                dashStyle: 'DashDot',
                showLegend: true,
            },
            {
                type: 'spline',
                name: 'Temp. Consigne (°C)',
                hash: 'Zone',
                data: data?.map((el) => {
                    if ('setTemp' in el) {
                        return parseInt(el?.setTemp * 10) / 10
                    }
                    if (el?.Zone !== undefined && el?.Zone !== 0) return parseInt(el?.Zone * 10) / 10
                    else return null
                }),
                yAxis: 0,
                visible: true,
                color: electricGreen,
                dashStyle: 'DashDot',
                showLegend: true,
            },
        ]
        setSeries(_series)
        // if (chartComponentRef.current && chartComponentRef.current.chart) {
        //     console.log('update _series', _series)
        //     chartComponentRef.current.chart.update({
        //         series: _series
        //     });
        // }
        let _labels = data?.map((el) => el?.label)
        setLabels(_labels)
        setChartOptions((prevOptions) => ({
            ...prevOptions,
            xAxis: {
                ...prevOptions.xAxis,
                categories: data?.map((el) => el?.label),
            },
            series: _series,
        }))
        setUpdating(false)
        setChartKey(Date.now())
        //    chartRef.current.chart.xAxis[0].setCategories(_labels);
        // chartRef = useRef(_labels);
    }, [data])

    return (
        <Card style={{ height: '100%', maxWidth: width - 32 }} sx={{ mb: 2 }}>
            <CardContent
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                }}
            >
                <Grid container spacing={1}>
                    <Grid item md={4}>
                        <CardTitle title={'Détails de la zone'} />
                    </Grid>
                    <Grid item md={12}></Grid>
                </Grid>

                {series?.length > 0 && !updating && <HighchartsChart key={chartKey} options={chartOptions} />}

                <Legend
                    legends={series
                        ?.filter((el) => el?.showLegend)
                        ?.map((el) => {
                            return { color: el?.color, content: el?.name }
                        })}
                    style={{
                        flexDirection: 'row',
                        marginBottom: 20,
                        justifyContent: 'center',
                    }}
                    legendStyle={{ marginRight: 10 }}
                />
            </CardContent>
        </Card>
    )
}
