import React from 'react'
import useAuth from '../context/useAuth'
import AuthRoutes from './authRoutes'
import MainRoutes from './mainRoutes'
import Loader from '../components/fallbacks/loader.component'

const RoutesWrapper = () => {
    const { me, token } = useAuth()
    if (!token) {
        return <AuthRoutes pathname={window.location.pathname} />
    }
    if (!me) {
        return <Loader />
    }

    const defaultRoute = me.userPermissions?.find((el) => el?.section === 'DashboardGroupView')
        ? '/'
        : me?.userPermissions?.find((el) => el?.section === 'RealtimeView')
          ? '/realtime'
          : '/settings/security'

    return <MainRoutes defaultRoute={defaultRoute} />
}

export default RoutesWrapper
