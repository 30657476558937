import PilotImg from '../../assets/img/help/placeholders/pilot.png'
import ConfigImg from '../../assets/img/help/placeholders/configuration.png'
import InterfaceImg from '../../assets/img/help/placeholders/interface.png'
import AllImg from '../../assets/img/help/placeholders/all.png'
import ReceptionImg from '../../assets/img/help/placeholders/reception.png'
import TechnicalImg from '../../assets/img/help/placeholders/tech.png'
import DirectionImg from '../../assets/img/help/placeholders/direction.png'
import Tuto1 from '../../assets/img/help/tuto_1.png'
import Tuto3 from '../../assets/img/help/tuto_3.png'
import Tuto4 from '../../assets/img/help/tuto_4.png'
import Tuto5 from '../../assets/img/help/tuto_5.png'
import Tuto6 from '../../assets/img/help/tuto_6.png'
import Tuto7 from '../../assets/img/help/tuto_7.png'
import Tuto8 from '../../assets/img/help/tuto_8.png'
import Tuto9 from '../../assets/img/help/tuto_9.png'
import Tuto21 from '../../assets/img/help/tuto_2.1.png'
import Tuto22 from '../../assets/img/help/tuto_2.2.png'
import Tuto10 from '../../assets/img/help/tuto_10.png'
import Tuto11 from '../../assets/img/help/tuto_11.png'
// dashboard/src/assets/img/help/placeholders/questions/complaint.png dashboard/src/assets/img/help/placeholders/questions/add_user.png dashboard/src/assets/img/help/placeholders/questions/agrid.png dashboard/src/assets/img/help/placeholders/questions/bug.png dashboard/src/assets/img/help/placeholders/questions/cold.png dashboard/src/assets/img/help/placeholders/questions/connexion.png dashboard/src/assets/img/help/placeholders/questions/contact.png dashboard/src/assets/img/help/placeholders/questions/difference.png dashboard/src/assets/img/help/placeholders/questions/email.png dashboard/src/assets/img/help/placeholders/questions/misunderstanding.png dashboard/src/assets/img/help/placeholders/questions/mode.png dashboard/src/assets/img/help/placeholders/questions/not_working.png dashboard/src/assets/img/help/placeholders/questions/risks.png dashboard/src/assets/img/help/placeholders/questions/savings.png dashboard/src/assets/img/help/placeholders/questions/target.png dashboard/src/assets/img/help/placeholders/questions/temp_not_working.png
import ComplaintImg from '../../assets/img/help/placeholders/questions/complaint.png'
import AddUserImg from '../../assets/img/help/placeholders/questions/add_user.png'
import AgridImg from '../../assets/img/help/placeholders/questions/agrid.png'
import BugImg from '../../assets/img/help/placeholders/questions/bug.png'
import ColdImg from '../../assets/img/help/placeholders/questions/cold.png'
import ConnexionImg from '../../assets/img/help/placeholders/questions/connexion.png'
import ContactImg from '../../assets/img/help/placeholders/questions/contact.png'
import DifferenceImg from '../../assets/img/help/placeholders/questions/difference.png'
import EmailImg from '../../assets/img/help/placeholders/questions/email.png'
import MisunderstandingImg from '../../assets/img/help/placeholders/questions/misunderstanding.png'
import ModeImg from '../../assets/img/help/placeholders/questions/mode.png'
import NotWorkingImg from '../../assets/img/help/placeholders/questions/not_working.png'
import RisksImg from '../../assets/img/help/placeholders/questions/risks.png'
import SavingsImg from '../../assets/img/help/placeholders/questions/savings.png'
import TargetImg from '../../assets/img/help/placeholders/questions/target.png'
import SendCommand from '../../assets/img/help/placeholders/questions/send_command.png'
import Emergency from '../../assets/img/help/placeholders/questions/emergency.png'
import Realtime from '../../assets/img/help/placeholders/questions/realtime.png'
import GroupImg from '../../assets/img/help/placeholders/questions/group.png'
import ZoneConfigImg from '../../assets/img/help/placeholders/questions/zone_config.png'
import SiteConfigImg from '../../assets/img/help/placeholders/questions/site_config.png'
import ToggleImg from '../../assets/img/help/placeholders/questions/toggle.png'
import ChangeTmpImg from '../../assets/img/help/placeholders/questions/change_tmp.png'
import SaveZoneImg from '../../assets/img/help/placeholders/questions/save_zone.png'
import { PMSQuestion } from './components/questions/pms.question'

const ANY = 'tag_any'
const DASHBOARD = 'tag_dashboard'
const PILOT = 'tag_pilot'
const CONFIGURATION = 'tag_configuration'
const RECEPTION = 'tag_reception'
const TECHNICAL = 'tag_technical'
const OWNER = 'tag_owner'

export const tags = [
    { target: DASHBOARD, label: 'Interface', section: 1, img: InterfaceImg },
    { target: PILOT, label: 'Pilotage', section: 1, img: PilotImg },
    { target: CONFIGURATION, label: 'Configuration', section: 1, img: ConfigImg },
    {
        target: ANY,
        label: 'Fonctionnement du pilote AGRID',
        section: 1,
        img: AllImg,
    },
    { target: RECEPTION, label: 'Réception et équipe opérationnelle', section: 2, img: ReceptionImg },
    { target: TECHNICAL, label: 'Responsables techniques', section: 2, img: TechnicalImg },
    { target: OWNER, label: 'Propriétaires - Gestionnaires - Directions', section: 2, img: DirectionImg },
]

export const questions = [
    {
        label: 'Mon client/locataire a froid',
        featured: true,
        description: `Sur certains modèles de télécommandes murales, les informations de température sont visibles alors que le système est éteint (climatisation / chauffage à l’arrêt).<br/>
  Il arrive donc que le client modifie les températures sur la télécommande murale et que ces changements ne soient pas pris en compte, simplement car le chauffage ou la climatisation sont éteints. <br/>
  Le client a-t-il pensé à allumer le chauffage et la climatisation (voyant ‘ON’ lumineux activé) ?`,
        summary:
            'Vérifiez si le client a allumé le chauffage ou la climatisation. Les changements sur la télécommande peuvent ne pas être pris en compte si le système est éteint.',
        id: 1,
        tags: [PILOT, RECEPTION, TECHNICAL],
        emoji: '🥶',
        img: ColdImg,
    },
    {
        label: 'Changer de mode',
        featured: true,
        description: `Chez Agrid, nous ne changeons actuellement pas le mode de votre climatiseur. En effet, changer le mode n'est pas un geste anodin, car tous les climatiseurs liés au même moteur doivent avoir le même mode. <br/>
    Pour éviter d'endommager les moteurs, nous ne changeons pas le mode. Si votre mode a changé, Agrid n'est pas à l'initiative de cette modification et il est important de contacter votre mainteneur.`,
        summary:
            "Agrid ne modifie pas le mode de votre climatiseur pour éviter d'endommager les moteurs. Contactez votre mainteneur si le mode a changé.",
        id: 2,
        tags: [CONFIGURATION, TECHNICAL],
        emoji: '🔄',
        img: ModeImg,
    },
    {
        label: "Faire plus d'économies",
        featured: true,
        description: `Il est toujours possible de faire encore plus d'économies. L'algorithme d'Agrid respecte les bornes de température que vous avez configurées. En modifiant celles-ci, vous permettez à notre intelligence artificielle de gagner en marge de manoeuvre et donc de faire encore plus d'économies. <br/>
    Pour cela, rendez-vous dans la section 'Configuration' de votre compte Agrid.`,
        summary:
            "Modifiez les bornes de température pour permettre à l'intelligence artificielle d'Agrid d'améliorer encore plus les économies d'énergie.",
        id: 3,
        tags: [ANY, OWNER],
        emoji: '💰',
        img: SavingsImg,
    },
    {
        featured: true,
        label: 'Je ne sais pas avec quelle adresse mail me connecter ',
        description: `En tant qu’administrateur(trice), l’adresse e-mail à utiliser est communiquée par l’équipe AGRID. En tant qu’utilisateur, l’administrateur(trice) pourra ajouter votre adresse email depuis l’onglet « Configuration » sur la plateforme d’AGRID.`,
        summary:
            "L'administrateur peut ajouter votre adresse e-mail via l'onglet Configuration sur la plateforme AGRID.",
        id: 4,
        tags: [DASHBOARD, RECEPTION],
        emoji: '📧',
        img: EmailImg,
    },
    {
        label: 'Connexion - Je ne connais pas mon mot de passe ?',
        description: `Vous pouvez, à tout moment, utiliser le bouton « mot de passe oublié ». Un nouveau mot de passe vous sera envoyé par e-mail. (Une double authentification est mise en place pour sécuriser votre compte).`,
        summary:
            'Utilisez le bouton « mot de passe oublié » pour réinitialiser votre mot de passe. La double authentification sécurise votre compte.',
        id: 5,
        tags: [DASHBOARD, RECEPTION],
        emoji: '🔑',
        img: ConnexionImg,
    },
    {
        label: 'Configuration – Comment ajouter un utilisateur ?',
        description: `En tant qu’administrateur(trice), vous pouvez ajouter (et supprimer) des utilisateurs depuis l’onglet « Configuration » puis « Configuration du site ». Choisissez le statut « Admin » ou « utilisateur ».`,
        summary:
            "Ajoutez ou supprimez des utilisateurs dans l'onglet Configuration en choisissant le statut Admin ou Utilisateur.",
        id: 6,
        tags: [CONFIGURATION, OWNER],
        emoji: '👥',
        img: AddUserImg,
    },
    {
        label: 'Configuration – Je ne sais plus quelles sont les adresses emails autorisées à se connecter à l’interface ?',
        description: `Il est possible de consulter à tout moment la liste des adresses emails paramétrées dans l’interface AGRID dans l’onglet « Configuration ».`,
        summary: "Consultez la liste des adresses e-mails autorisées dans l'onglet Configuration de l'interface AGRID.",
        id: 7,
        tags: [CONFIGURATION, OWNER],
        emoji: '📧',
        img: ContactImg,
    },
    {
        label: 'Je ne parviens pas à changer une température depuis l’interface ?',
        description: `Assurez-vous de procéder comme expliqué ici, et vérifiez que vous avez bien cliqué sur le bouton « Enregistrer ». La modification peut prendre quelques secondes à s'afficher.`,
        summary:
            "Assurez-vous d'avoir cliqué sur « Enregistrer » après modification. Les changements peuvent prendre quelques secondes pour apparaître.",
        id: 8,
        tags: [DASHBOARD, RECEPTION],
        emoji: '🌡️',
        img: ChangeTmpImg,
    },
    {
        label: 'Certaines températures ne « fonctionnent » pas ?',
        description: `Seules les températures dans les bornes autorisées sont permises. Si une consigne dépasse ces bornes, elle sera automatiquement corrigée.`,
        summary:
            'Les températures hors bornes configurées sont corrigées automatiquement. Seules les consignes dans les bornes sont appliquées.',
        id: 9,
        tags: [PILOT, ANY, RECEPTION],
        emoji: '🌡️',
        img: DifferenceImg,
    },
    {
        label: 'Je ne comprends pas une consigne passée automatiquement ?',
        description: `Le pilote automatique d’AGRID respecte les bornes de température paramétrées pour optimiser la consommation énergétique tout en préservant le confort des clients.`,
        summary:
            'Le pilote automatique ajuste les consignes dans les bornes définies pour optimiser la consommation énergétique et maintenir le confort.',
        id: 10,
        tags: [ANY, RECEPTION],
        emoji: '🌡️',
        img: MisunderstandingImg,
    },
    {
        label: 'La température demandée n’est pas atteinte ?',
        description: `Si la température extérieure est extrême, le système CVC peut ne pas être assez puissant pour atteindre la consigne de température.`,
        summary:
            "Les températures extrêmes extérieures peuvent empêcher le système CVC d'atteindre la température demandée.",
        id: 11,
        tags: [ANY, OWNER],
        emoji: '🌡️',
        img: TargetImg,
    },
    {
        label: 'La température ressentie n’est pas celle mesurée dans la pièce ?',
        description: `Les températures mesurées proviennent des sondes placées dans les unités de climatisation ou les télécommandes murales. Vérifiez leur emplacement ou leur bon fonctionnement.`,
        summary: "La température mesurée peut varier selon l'emplacement des sondes. Vérifiez leur bon fonctionnement.",
        id: 12,
        tags: [ANY, TECHNICAL],
        emoji: '🌡️',
        img: BugImg,
    },
    {
        label: 'Un client se plaint de la température dans sa chambre ?',
        description: `Les plaintes peuvent être dues à plusieurs raisons, comme un sous-dimensionnement du système CVC ou des réglages inadéquats. Vous pouvez désactiver le pilote automatique pour la chambre concernée.`,
        summary:
            'Désactivez le pilote automatique pour une chambre si un client se plaint de la température durant son séjour.',
        id: 13,
        tags: [ANY, RECEPTION],
        emoji: '🌡️',
        img: ComplaintImg,
    },
    {
        label: 'Un client me demande en quoi consiste AGRID ?',
        description: `AGRID est conçu pour réduire l'impact carbone des bâtiments tertiaires en optimisant l'usage des systèmes de chauffage et de climatisation.`,
        summary:
            "AGRID optimise l’utilisation des systèmes CVC pour réduire la consommation énergétique et l'impact carbone des bâtiments.",
        id: 14,
        tags: [ANY, OWNER],
        emoji: '🌡️',
        img: AgridImg,
    },
    {
        label: 'Mon système CVC ne semble plus fonctionner ?',
        description: `Débranchez et rebranchez le boîtier AGRID pour vérifier si le problème est lié à AGRID. Le pilotage reprendra automatiquement.`,
        summary: 'Débranchez puis rebranchez le boîtier AGRID pour vérifier si le problème provient du système AGRID.',
        id: 15,
        tags: [ANY, TECHNICAL],
        emoji: '🌡️',
        img: NotWorkingImg,
    },
    {
        label: 'Quels sont les risques pour mes systèmes ?',
        description: `AGRID est non-intrusif et n’endommage pas les systèmes CVC. Il agit comme un utilisateur en passant des consignes de température.`,
        summary:
            'AGRID est non-intrusif et ne peut pas endommager les systèmes CVC. Il agit comme un utilisateur standard.',
        id: 16,
        tags: [ANY, TECHNICAL],
        emoji: '🌡️',
        img: RisksImg,
    },
    {
        label: 'L’interface AGRID ne semble plus fonctionner ?',
        description: `Dans le cas où les services d’AGRID seraient momentanément indisponibles, votre système CVC continue de fonctionner, mais sans générer d’économies.`,
        summary:
            "Si AGRID est temporairement indisponible, le système CVC continue de fonctionner mais sans générer d'économies.",
        id: 17,
        tags: [DASHBOARD, TECHNICAL],
        emoji: '🌡️',
        img: BugImg,
    },
    {
        label: 'J’ai besoin d’aide, qui contacter ?',
        description: `Pour les questions liées à votre système CVC, contactez votre mainteneur. Pour les questions sur le pilotage ou les outils AGRID, contactez hello@a-grid.com.`,
        summary:
            'Contactez votre mainteneur pour les problèmes CVC, ou AGRID pour les questions de pilotage à hello@a-grid.com.',
        id: 18,
        tags: [ANY, RECEPTION],
        emoji: '🌡️',
        img: ContactImg,
    },
    {
        label: 'Configuration de votre site',
        featured: false,
        description: `Renseigner la surface, les horaires de Check-Out et de Check-In.<br/>
        Ajouter l’année et la consommation de référence par rapport à laquelle vous souhaitez mesurer les économies. (Nous recommandons celle utilisée dans le cadre du décret tertiaire lorsque le bâtiment est éligible).<br/>
        Ajouter, dans la partie droite, les adresses emails des utilisateurs de l’outil AGRID.<br/>
        <img src="${Tuto11}" alt="Tuto 1" class="help-image"/>`,
        summary: `Renseignez les informations de base de votre site, comme la surface, les horaires de Check-Out et de Check-In, l'année de référence et la consommation de référence.`,
        id: 't1',
        tags: [CONFIGURATION],
        emoji: '⚙️',
        img: SiteConfigImg,
    },
    {
        label: 'Configuration des zones',
        featured: false,
        description: `Indiquer pour chacune des zones le type de pièce (« Espaces communs », « Chambres », « Espaces de restauration », etc.).<br/>
        Pour cela, cliquer déposer dans la catégorie souhaitée ou bien en cliquant comme suit :<br/>
        <img src="${Tuto21}" alt="Tuto 21" class="help-image"/><br/>
        <img src="${Tuto22}" alt="Tuto 22" class="help-image"/>`,
        summary: `Définissez le type de pièce pour chaque zone de votre site, comme les chambres, les espaces communs ou les espaces de restauration.`,
        id: 't2',
        tags: [CONFIGURATION],
        emoji: '🏠',
        img: ZoneConfigImg,
    },
    {
        label: "Configuration d'une zone spécifique",
        featured: false,
        description: `En cliquant sur une zone, il sera possible de:<br/>
        - Modifier le nom de la zone si nécessaire (en cliquant sur le nom de la zone).<br/>
        - Définir les bornes de températures de confort. Cool: température de confort en été. Heat: température de confort en hiver.<br/>
        - Indiquer les horaires d’ouverture. Vous pouvez étendre la plage de disponibilité en cliquant sur les flèches, ou bien copier une configuration existante en cliquant sur l'icône à côté du jour.<br/>
        <img src="${Tuto3}" alt="Tuto 3" class="help-image"/>`,
        summary: `Personnalisez les réglages pour chaque zone de votre site, comme les bornes de température de confort et les horaires d'ouverture.`,
        id: 't3',
        tags: [CONFIGURATION],
        emoji: '📍',
        img: ConfigImg,
        video: 'https://www.tella.tv/video/clujt1vjt003j0fli140c6ce5/embed?b=1&title=1&a=1&loop=0&t=0&muted=0&wt=1',
    },
    {
        label: 'Enregistrer pour une zone spécifique',
        featured: false,
        description: `Plusieurs façons d'enregistrer vos modifications:<br/>
        - Pour la zone uniquement: utiliser cette option pour définir des réglages propres à une pièce.<br/>
        - Pour toutes les zones similaires: utiliser cette option pour répéter ces réglages automatiquement sur les autres zones.<br/>
        <img src="${Tuto4}" alt="Tuto 4" class="help-image"/>`,
        summary: `Enregistrez les modifications pour une zone spécifique ou pour toutes les zones similaires.`,
        id: 't4',
        tags: [CONFIGURATION],
        emoji: '💾',
        img: SaveZoneImg,
    },
    {
        label: 'Création de groupes',
        featured: false,
        description: `Un groupe permet de regrouper plusieurs zones et de les piloter ensemble.<br/>
        La configuration pourra alors être enregistrée pour toutes les zones du groupe. Il sera également possible de passer des commandes au niveau du groupe (depuis l’onglet pilotage).<br/>
        <img src="${Tuto5}" alt="Tuto 5" class="help-image"/>`,
        summary: `Regroupez plusieurs zones pour les piloter ensemble et enregistrer les réglages pour toutes les zones du groupe.`,
        id: 't5',
        tags: [CONFIGURATION],
        emoji: '🛠️',
        img: GroupImg,
    },
    {
        label: 'Activation du pilote automatique',
        featured: false,
        description: `Après une période d’observation d’environ un mois nécessaire pour que notre modèle thermique se calibre, il est possible d’activer le pilote automatique dans l’onglet « configuration du site ».<br/>
        Cliquer sur « Démarrer le pilote ». Le pilote pourra, par la suite, être activé ou désactivé à tout moment en un seul clic. Le pilote est ensuite activé/désactivé zone par zone.<br/>
        <img src="${Tuto6}" alt="Tuto 6" class="help-image"/>`,
        summary: `Activez le pilote automatique pour optimiser la consommation énergétique de votre site.`,
        id: 't6',
        tags: [CONFIGURATION],
        emoji: '🚀',
        img: PilotImg,
    },
    {
        label: 'Activer/désactiver le pilote pour une zone',
        featured: false,
        description: `De la même manière que pour les autres réglages, il est possible d’enregistrer pour toutes les zones similaires, pour les zones du groupe, ou pour une zone uniquement.<br/>
        Il est ainsi possible d’activer/désactiver très rapidement le pilote pour toutes les chambres ou pour une seule chambre pendant le séjour d’un client.<br/>
        <img src="${Tuto7}" alt="Tuto 7" class="help-image"/>`,
        summary: `Activez ou désactivez le pilote automatique pour une zone spécifique, toutes les zones similaires ou les zones d'un groupe.`,
        id: 't7',
        tags: [CONFIGURATION],
        emoji: '🔄',
        img: ToggleImg,
    },
    {
        label: 'Suivi en temps réel',
        featured: false,
        description: `L’onglet Pilotage permet de voir en temps réel l’usage des systèmes CVC (chauffage, ventilation et climatisation):<br/>
        - Pourcentage de machines en marche<br/>
        - Température moyenne mesurée<br/>
        - Les bornes de températures d’une zone sont indiquées par les températures observables dans le coin supérieur gauche de la zone.<br/>
        - L’occupation d’une chambre s’observe dans le petit carré dans le coin inférieur droit : si le carré est gris la chambre est inoccupée, si il est bleu alors la chambre est occupée.<br/>
        Le bouton « Pilotage du site » permet de passer une consigne pour l’ensemble des zones.<br/>
        <img src="${Tuto8}" alt="Tuto 8" class="help-image"/>`,
        summary: `Consultez en temps réel l'utilisation des systèmes CVC et passez des consignes pour l'ensemble des zones.`,
        id: 't8',
        tags: [PILOT, DASHBOARD],
        emoji: '🖥️',
        img: Realtime,
    },
    {
        label: 'Passer une consigne depuis l’interface',
        featured: false,
        description: `Il est possible d’accéder aux températures passées ainsi qu’à la température mesurée en temps réel dans la zone.<br/>
        Vous pouvez modifier la température mesurée, la température de consigne, la vitesse de ventilation, et le statut de la climatisation (on/off).<br/>
        <img src="${Tuto9}" alt="Tuto 9" class="help-image"/>`,
        summary: `Modifiez les températures, la vitesse de ventilation et le statut de la climatisation depuis l'interface.`,
        id: 't9',
        tags: [PILOT, DASHBOARD],
        emoji: '🖱️',
        img: SendCommand,
    },
    // {
    //     label: "Procédure d'urgence",
    //     featured: false,
    //     description: `Il est possible de changer rapidement les paramètres de votre site pour une durée limitée. Cette fonctionnalité est utile en cas d'urgence, par exemple pour un besoin spécifique pour un client.<br/>
    //     Pendant 24h, nous élargissons les bornes lorsque cela est possible et nous désactivons le pilote automatique.<br/>
    //     <img src="${Tuto10}" alt="Tuto 10" class="help-image"/>`,
    //     summary: `Modifiez temporairement les paramètres de votre site en cas d'urgence pour élargir les bornes et désactiver le pilote automatique.`,
    //     id: 't10',
    //     tags: [PILOT],
    //     emoji: '⚠️',
    //     img: Emergency,
    // },

    {
        label: 'Comment connecter mon PMS à AGRID ?',
        featured: false,
        description: null,
        summary: `Vous pouvez connecter votre PMS à AGRID pour synchroniser les données et optimiser les économies d'énergie.`,
        id: 't11',
        tags: [DASHBOARD, OWNER],
        emoji: '💤',
        img: Realtime,
        component: PMSQuestion,
    },
]
