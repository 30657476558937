import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch'
import {
    Alert,
    Button,
    Card,
    CardContent,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Snackbar,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Tooltip,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { electricGreen, redBackground } from '@/components/Basic/colors'
import { TitleComponent } from '@/components/Basic/library'
import { ImageInput } from '@/components/Global/image.input.component'
import { getPmsGlobal } from '@/services/pms.service'
import { togglePilot } from '@/services/realtime.service'
import { uploadFile } from '@/services/s3.service'
import { saveSiteNameAlt } from '@/services/site.service'
import { createUser, getAllUsers } from '@/services/user.service'
import { makeid, sleep } from '@/utils/global.utils'
import { validateEmail } from '@/utils/regex.utils'
import { UserPopUp } from './user.pop-up.component'
import { useSite } from '../../useSite'

const isAdmin = (hash, permissions, site) => {
    return permissions?.find(
        (perm) =>
            perm?.hash == hash &&
            perm?.SiteId == site.siteId &&
            perm?.Section == 'ConstraintsView' &&
            perm?.GrantType == 'Manage'
    )
}

export const SiteComponent = ({ checkIn, checkOut, autoSave, observationEndDate, weekendCheckIn, weekendCheckOut }) => {
    const [userPopup, setShowUserPopup] = useState(false)
    const { site, objectsQuery } = useSite()
    const [users, setUsers] = useState(null)
    const objects = objectsQuery?.data
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [newName, setNewName] = useState()
    const [newSurface, setNewSurface] = useState('')
    const [newRefYear, setNewRefYear] = useState('')
    const [newRefValue, setNewRefValue] = useState('')
    const [pms, setPms] = useState()
    const [pmsVendorId, setPmsVendorId] = useState()
    const [pilot, setPilot] = useState(0)
    const [pilotLoader, setPilotLoader] = useState(false)
    const [open, setOpen] = useState(false)
    const [pictureLoading, setPictureLoading] = useState(false)
    const [showCheckinError, setShowCheckinError] = useState(false)
    const [permissions, setPermissions] = useState([])

    const [showWeekend, setShowWeekend] = useState(weekendCheckIn || weekendCheckOut)

    useEffect(() => {
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [site])

    useEffect(() => {
        const siteObject = objects?.find((el) => el?.objectTypeId === 1)
        setPilot(null)
        if (siteObject) {
            setPilot(siteObject.pilot)
        }
    }, [objects])

    useEffect(() => {
        setPmsVendorId(site?.pmsVendorId || null)
        setPms(site.pms || null)
    }, [site])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (pms && pmsVendorId && site.hash) {
                _fetch()
            }
        }, 500)
        return () => clearTimeout(timer)
    }, [pms, pmsVendorId])
    const _fetch = async () => {
        // let res = await registerPms(site.hash, { pms, pmsVendorId })
        // if (!res?.success) setPmsStatus("error")
        // else setPmsStatus("success")
    }

    const init = async () => {
        let res = await getAllUsers(site.siteId)
        let _users = []
        if (res?.users) {
            _users = res?.users
                ?.filter((user) => {
                    return user?.SiteId == site.siteId
                })
                ?.map((el) => {
                    return { ...el, hash: makeid() }
                })
            setUsers(_users)
        }
        if (res?.permissions) {
            // for each permission add hash corresponding to user
            let _permissions = res?.permissions?.map((perm) => {
                let user = _users?.find((user) => user?.email == perm?.email)
                if (user) {
                    return {
                        ...perm,
                        hash: user?.hash,
                    }
                } else return perm
            })
            setPermissions(_permissions)
        }
        res = await getPmsGlobal()
    }
    const changeUser = (email, role, hash) => {
        let _newUsers = users.map((el) => {
            if (el.hash != hash) return el
            else {
                let newRoles = { ...el.roles }
                newRoles[site.siteId] = role
                return {
                    ...el,
                    email,
                    roles: newRoles,
                }
            }
        })
        setUsers(_newUsers)
    }

    const changeUserPermissions = (hash, section, grant) => {
        console.log('hash: ', hash, section, grant)
        let _newPermissions = permissions
        let find = _newPermissions?.find(
            (perm) => perm?.hash == hash && perm?.SiteId == site.siteId && perm?.Section == section
        )
        console.log('find: ', find)
        if (find) {
            _newPermissions = _newPermissions?.map((perm) => {
                if (perm?.hash == hash && perm?.SiteId == site.siteId && perm?.Section == section) {
                    return {
                        ...perm,
                        GrantType: grant,
                    }
                } else return perm
            })
        } else {
            _newPermissions = [
                ..._newPermissions,
                {
                    SiteId: site.siteId,
                    Section: section,
                    GrantType: grant,
                    hash: hash,
                },
            ]
        }

        find = _newPermissions?.find(
            (perm) => perm?.hash == hash && perm?.SiteId == site.siteId && perm?.Section == section
        )
        console.log('find: ', find)

        setPermissions(_newPermissions)
    }

    const deleteUser = (email) => {
        let _newUsers = users.map((user) => {
            if (user?.email == email) {
                return {
                    ...user,
                    delete: true,
                }
            } else return user
        })
        setUsers(_newUsers)
    }

    const submit = async () => {
        let _selectedUsers = users?.filter((el) => {
            let siteId = isNaN(site.siteId) ? site.siteId : site.siteId.toString()
            return el?.SiteId == siteId
        })
        // remove users that are identical to previous users
        setError('')
        for (let i = 0; i < _selectedUsers?.length; i++) {
            let email = _selectedUsers[i]?.email
            let isValid = validateEmail(email)
            if (!isValid || email == 'to_change@gmail.com') {
                setError("Merci d'entrer un email valide")
                return
            }
        }
        setLoading(true)
        setOpen(false)
        for (let i = 0; i < _selectedUsers?.length; i++) {
            console.log('email: ', _selectedUsers[i])

            const res = await createUser(
                {
                    email: _selectedUsers[i]?.email,
                    userRoles: _selectedUsers[i]?.roles,
                    siteId: site.siteId,
                    delete: _selectedUsers[i]?.delete,
                    permissions: permissions?.filter((el) => el?.hash == _selectedUsers[i]?.hash),
                },
                site.hash
            )
            if (res?.errorMsg) {
                setError(res?.errorMsg)
            } else {
                setOpen(true)
            }
        }

        setLoading(false)
    }

    const addUser = () => {
        let roles = {}
        roles[site.siteId] = 'user'
        let hash = makeid()
        let newUser = {
            Type: 'user',
            email: 'to_change@gmail.com',
            roles,
            hash: hash,
            SiteId: site.siteId,
        }
        let _newUsers = [newUser, ...users]
        console.log('_newUsers: ', _newUsers)

        setUsers(_newUsers)
        let _newPermissions = permissions
        _newPermissions = [
            ..._newPermissions,
            {
                SiteId: site.siteId,
                Section: 'DashboardSiteView',
                GrantType: 'Edit',
                hash: hash,
            },
            {
                SiteId: site.siteId,
                Section: 'DocumentsView',
                GrantType: 'Edit',
                hash: hash,
            },
            {
                SiteId: site.siteId,
                Section: 'RealtimeView',
                GrantType: 'Edit',
                hash: hash,
            },
            {
                SiteId: site.siteId,
                Section: 'ConstraintsView',
                GrantType: '',
                hash: hash,
            },
        ]
        setPermissions(_newPermissions)
    }

    const changePicture = async (file) => {
        setPictureLoading(true)
        console.log('file: ', file)
        let path = await uploadFile(file, 'pictures')
        if (path) {
            await saveSiteNameAlt(site.hash, {
                siteName: site.name,
                PictureUrl: path,
            })
            setOpen(true)
        }
        setPictureLoading(false)
    }

    const changeName = async (name) => {
        await saveSiteNameAlt(site.hash, {
            siteName: name,
            PictureUrl: site.pictureUrl,
        })
        setNewName(name)
    }

    const changeSurface = async (surface) => {
        setNewSurface(surface)
        await saveSiteNameAlt(site.hash, {
            siteName: site.name,
            Surface: surface,
        })
    }

    const changeRefYear = async (_value) => {
        setNewRefYear(_value)
        await saveSiteNameAlt(site.hash, {
            siteName: site.name,
            RefYear: _value,
        })
    }

    const changeRefValue = async (_value) => {
        setNewRefValue(_value)
        await saveSiteNameAlt(site.hash, {
            siteName: site.name,
            RefValue: _value,
        })
    }

    return (
        <Card style={{ marginTop: 30 }}>
            {userPopup ? (
                <UserPopUp
                    setShowUserPopup={() => {
                        setShowUserPopup(false)
                    }}
                />
            ) : null}

            <CardContent>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12} style={{ position: 'relative' }}>
                                {pictureLoading && (
                                    <div style={{ position: 'absolute', top: 10, left: 10 }}>
                                        <CircularProgress />
                                    </div>
                                )}
                                <ImageInput
                                    url={site.pictureUrl}
                                    onChange={(e) => {
                                        changePicture(e)
                                    }}
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Nom du site"
                                    value={newName || site.name || ''}
                                    type="text"
                                    onChange={(e) => changeName(e.target.value)}
                                    // error={props.errors.indexOf(`Name`) > -1}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField
                                    fullWidth
                                    label={`Surface (m2)`}
                                    value={newSurface || site.surface || ''}
                                    type="text"
                                    onChange={(e) => changeSurface(e.target.value)}
                                    endAdornment={<InputAdornment position="end">m2</InputAdornment>}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Année de référence"
                                    value={newRefYear || site.refYear || ''}
                                    type="number"
                                    onChange={(e) => changeRefYear(e.target.value)}
                                    // error={props.errors.indexOf(`Name`) > -1}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <FormControl>
                                    <InputLabel id="pms-select-label">Conso. référence</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        label="Conso. référence"
                                        value={newRefValue || site.refValue || ''}
                                        type="number"
                                        onChange={(e) => changeRefValue(e.target.value)}
                                        endAdornment={
                                            newRefValue || site.refValue ? (
                                                <InputAdornment position="end">kWh/m2/an</InputAdornment>
                                            ) : null
                                        }
                                    />
                                </FormControl>
                                {/* <TextField
                  fullWidth
                  label="Conso. référence"
                  value={newRefValue || site.refValue || ""}
                  type="text"
                  onChange={(e) => changeRefValue(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">kWh</InputAdornment>
                  }
                  // error={props.errors.indexOf(`Name`) > -1}
                /> */}
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Grid container spacing={2}>
                                    {site.siteType === 'Hotel' && (
                                        <Grid item xs={6} sm={6} md={6}>
                                            <CustomSelect
                                                value={checkOut}
                                                errorHandler={setShowCheckinError}
                                                autoSave={autoSave}
                                                max={checkIn}
                                                label="Check-Out"
                                                type="checkout"
                                            />
                                        </Grid>
                                    )}
                                    {site.siteType === 'Hotel' && (
                                        <Grid item xs={6} sm={6} md={6}>
                                            <CustomSelect
                                                value={checkIn}
                                                errorHandler={setShowCheckinError}
                                                autoSave={autoSave}
                                                min={checkOut}
                                                label="Check-In"
                                                type="checkin"
                                            />
                                        </Grid>
                                    )}
                                    {site.siteType === 'Hotel' ? (
                                        <Grid item md={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={showWeekend}
                                                        onChange={(e) => {
                                                            setShowWeekend(e.target.checked)
                                                            if (!e.target.checked) {
                                                                autoSave({
                                                                    _weekendCheckIn: null,
                                                                    _weekendCheckOut: null,
                                                                })
                                                            }
                                                        }}
                                                        color="primary"
                                                    />
                                                }
                                                label="Checkout différent le Week-end"
                                            />
                                        </Grid>
                                    ) : null}
                                    {showWeekend ? (
                                        <>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <CustomSelect
                                                    value={weekendCheckOut}
                                                    errorHandler={setShowCheckinError}
                                                    autoSave={autoSave}
                                                    max={weekendCheckIn}
                                                    label="Weekend Check-Out"
                                                    type="checkout"
                                                    weekend={true}
                                                    defaultValue="10:00"
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <CustomSelect
                                                    value={weekendCheckIn}
                                                    errorHandler={setShowCheckinError}
                                                    autoSave={autoSave}
                                                    min={weekendCheckOut}
                                                    label="Weekend Check-In"
                                                    type="checkin"
                                                    weekend={true}
                                                    defaultValue="15:00"
                                                />
                                            </Grid>
                                        </>
                                    ) : null}
                                    {site.siteType == 'Hotel' && (
                                        <Grid item md={12} xs={12}>
                                            {showCheckinError ? (
                                                <Alert severity="warning" sx={{ width: '100%' }} on>
                                                    Le check-in doit être après check-out.
                                                </Alert>
                                            ) : (
                                                <br />
                                            )}
                                        </Grid>
                                    )}

                                    <Grid item md={6} xs={12}>
                                        <Button
                                            //   variant={pilot ? "outlined" : "contained"}
                                            endIcon={
                                                pilotLoader ? (
                                                    <CircularProgress
                                                        style={{ color: pilot ? '' : 'white' }}
                                                        size={12}
                                                    />
                                                ) : pilot && observationEndDate ? (
                                                    <DoDisturbOnIcon />
                                                ) : (
                                                    <RocketLaunchIcon />
                                                )
                                            }
                                            style={{
                                                background: pilot ? redBackground : electricGreen,
                                                color: 'white',
                                            }}
                                            fullWidth
                                            disabled={!observationEndDate || pilotLoader}
                                            onClick={async () => {
                                                const siteObject = objects?.find((el) => el?.objectTypeId === 1)
                                                console.log('siteObject', siteObject)
                                                if (siteObject?.objectId) {
                                                    setPilotLoader(true)
                                                    await sleep(1000)
                                                    await togglePilot(site.hash, {
                                                        objectIds: [siteObject.objectId],
                                                        newValue: 1 - pilot,
                                                        isSite: true,
                                                    })
                                                    objectsQuery.refetch()
                                                    setPilotLoader(false)
                                                    // setPilot(1 - pilot)
                                                }
                                            }}
                                        >
                                            {pilot && observationEndDate ? 'Arrêter le pilote' : 'Démarrer le pilote'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* {site.siteType === "Hotel" && (
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6}>
                    <TextField
                      type="time"
                      placeholder="12:00"
                      label="Check-In"
                      style={{ width: "100%" }}
                      value={checkIn || "15:00"}
                      onChange={(e) => {
                        autoSave({ _checkin: e.target.value });
                      }}
                    />
                  </Grid>
                </Grid>
              )} */}
                        </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <div style={{ color: 'red' }}>{error}</div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <TitleComponent title="Utilisateurs" />
                            <div>
                                <Button
                                    onClick={() => {
                                        addUser()
                                    }}
                                >
                                    Ajouter
                                </Button>
                                {loading ? (
                                    <CircularProgress size={16} />
                                ) : (
                                    <Button
                                        onClick={() => {
                                            submit()
                                        }}
                                    >
                                        Enregistrer
                                    </Button>
                                )}
                            </div>
                        </div>
                        <TableContainer sx={{ maxHeight: 320, maxWidth: '100%', overflow: 'scroll' }}>
                            <Table stickyHeader aria-label="sticky table" style={{ maxWidth: '100%' }}>
                                <TableBody style={{ maxWidth: '100%' }}>
                                    {/* <Alert severity="info">Contactez Agrid pour ajouter ou modifier des administrateurs</Alert> */}

                                    {users?.length > 0 ? (
                                        users
                                            ?.filter((el) => {
                                                let siteId = isNaN(site.siteId) ? site.siteId : site.siteId?.toString()
                                                return (
                                                    el?.SiteId == siteId &&
                                                    !el?.email?.includes('a-grid.com') &&
                                                    !el?.delete
                                                )
                                            })
                                            ?.map((row) => {
                                                return (
                                                    <UserRow
                                                        key={row.UserId}
                                                        row={row}
                                                        changeUser={changeUser}
                                                        deleteUser={deleteUser}
                                                        permissions={permissions}
                                                        site={site}
                                                        changeUserPermissions={changeUserPermissions}
                                                    />
                                                )
                                            })
                                    ) : (
                                        <TableRow>
                                            <TableCell>
                                                {users?.length === 0 ? (
                                                    <Alert severity="info">Aucun utilisateur n'a été ajouté…</Alert>
                                                ) : (
                                                    <CircularProgress />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </CardContent>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={() => setOpen(false)}
            >
                <Alert severity="success" sx={{ width: '100%' }} on>
                    Modifications enregistrées
                </Alert>
            </Snackbar>
        </Card>
    )
}

const UserRow = ({ row, changeUser, deleteUser, permissions, site, changeUserPermissions }) => {
    let is_admin = isAdmin(row?.hash, permissions, site)
    let pilot_permission = permissions?.find(
        (perm) =>
            perm?.hash == row?.hash &&
            perm?.SiteId == site.siteId &&
            perm?.Section == 'RealtimeView' &&
            perm?.GrantType !== ''
    )
    let dashboard_permission = permissions?.find(
        (perm) =>
            perm?.hash == row?.hash &&
            perm?.SiteId == site.siteId &&
            perm?.Section == 'DashboardSiteView' &&
            perm?.GrantType !== ''
    )
    let documents_permission = permissions?.find(
        (perm) =>
            perm?.hash == row?.hash &&
            perm?.SiteId == site.siteId &&
            perm?.Section == 'DocumentsView' &&
            perm?.GrantType !== ''
    )
    let constraints_permission = permissions?.find(
        (perm) =>
            perm?.hash == row?.hash &&
            perm?.SiteId == site.siteId &&
            perm?.Section == 'ConstraintsView' &&
            perm?.GrantType !== ''
    )
    return (
        <TableRow hover tabIndex={-1}>
            <TableCell
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'center',
                    }}
                >
                    <TextField
                        style={{ width: 300 }}
                        value={row.email}
                        type="text"
                        onChange={(e) => changeUser(e.target.value, row?.roles[site.siteId], row.hash)}
                        // error={props.errors.indexOf(`Name`) > -1}
                    />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip
                            title={'Un administrateur pourra ajouter des utilisateurs et modifier les données du site.'}
                        >
                            <FormControlLabel
                                style={{ margin: 0 }}
                                control={
                                    <Checkbox
                                        checked={is_admin ? true : false}
                                        onChange={(e) => {
                                            console.log('e: ', e.target.checked)
                                            changeUserPermissions(
                                                row?.hash,
                                                'ConstraintsView',
                                                e.target.checked ? 'Manage' : 'Edit'
                                            )
                                        }}
                                    />
                                }
                                label="Admin"
                            />
                        </Tooltip>
                        <i
                            className="fa fa-trash"
                            style={{
                                padding: 2,
                                color: 'red',
                                cursor: 'pointer',
                                marginLeft: 10,
                            }}
                            onClick={() => deleteUser(row.email)}
                        />
                    </div>
                </div>

                <FormGroup style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={dashboard_permission ? true : false}
                                onChange={(e) => {
                                    changeUserPermissions(
                                        row?.hash,
                                        'DashboardSiteView',
                                        e.target.checked ? 'Edit' : ''
                                    )
                                }}
                                size="small"
                            />
                        }
                        label="Dashboard"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={pilot_permission !== undefined && pilot_permission !== '' ? true : false}
                                onChange={(e) => {
                                    changeUserPermissions(row?.hash, 'RealtimeView', e.target.checked ? 'Edit' : '')
                                }}
                                size="small"
                            />
                        }
                        label="Pilotage"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={constraints_permission ? true : false}
                                onChange={(e) => {
                                    changeUserPermissions(row?.hash, 'ConstraintsView', e.target.checked ? 'Edit' : '')
                                }}
                                size="small"
                            />
                        }
                        label="Configuration"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={documents_permission ? true : false}
                                onChange={(e) => {
                                    changeUserPermissions(row?.hash, 'DocumentsView', e.target.checked ? 'Edit' : '')
                                }}
                                size="small"
                            />
                        }
                        label="Mes documents"
                    />
                </FormGroup>
                {/* {
          is_admin ? <Chip
            label="admin"
            style={{
              background: "#ce93d8",
              color: "white",
            }}
          /> : <Chip
            label="utilisateur"
            style={{ background: "#e6ee9c" }}
          />
        } */}
            </TableCell>
        </TableRow>
    )
}

const CustomSelect = ({ value, errorHandler, autoSave, min, label, max, type, weekend, defaultValue }) => {
    const [selectedValue, setSelectedValue] = useState(value || defaultValue)

    useEffect(() => {
        if (value) {
            setSelectedValue(value)
        }
    }, [value])

    return (
        <>
            <InputLabel id="checkin-label" style={{ fontSize: 12 }}>
                {label}
            </InputLabel>
            <Select
                style={{ width: '100%' }}
                value={selectedValue}
                id="select-checkin"
                labelId="checkin-label"
                onChange={(e) => {
                    setSelectedValue(e.target.value)
                    errorHandler(false)
                    // check that checkin is after checkout
                    let checkin = type == 'checkin' ? e.target.value : max || '15:00'
                    let checkout = type == 'checkout' ? e.target.value : min || '11:00'
                    console.log('checkin: ', checkin, checkout)
                    let checkinHour = parseInt(checkin.split(':')[0])
                    let checkinMinute = parseInt(checkin.split(':')[1])
                    let checkoutHour = parseInt(checkout.split(':')[0])
                    let checkoutMinute = parseInt(checkout.split(':')[1])
                    let key =
                        type == 'checkin' && !weekend
                            ? '_checkin'
                            : type == 'checkout' && !weekend
                              ? '_checkout'
                              : type == 'checkin' && weekend
                                ? '_weekendCheckIn'
                                : '_weekendCheckOut'
                    if (checkinHour > checkoutHour) {
                        autoSave({ [key]: e.target.value })
                    } else if (checkinHour == checkoutHour) {
                        if (checkinMinute > checkoutMinute) {
                            autoSave({ [key]: e.target.value })
                        } else {
                            errorHandler(true)
                        }
                    } else {
                        errorHandler(true)
                    }
                }}
            >
                {type == 'checkin'
                    ? [
                          '11:00',
                          '11:30',
                          '12:00',
                          '12:30',
                          '13:00',
                          '13:30',
                          '14:00',
                          '14:30',
                          '15:00',
                          '15:30',
                          '16:00',
                          '16:30',
                          '17:00',
                          '17:30',
                          '18:00',
                          '18:30',
                          '19:00',
                          '19:30',
                          '20:00',
                          '20:30',
                      ].map((el) => {
                          return (
                              <MenuItem key={el} value={el + ':00'}>
                                  {el}
                              </MenuItem>
                          )
                      })
                    : [
                          '08:00',
                          '08:30',
                          '09:00',
                          '09:30',
                          '10:00',
                          '10:30',
                          '11:00',
                          '11:30',
                          '12:00',
                          '12:30',
                          '13:00',
                      ].map((el) => {
                          return (
                              <MenuItem key={el} value={el + ':00'}>
                                  {el}
                              </MenuItem>
                          )
                      })}
            </Select>
        </>
    )
}
