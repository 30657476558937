import { PermissionSection } from '@/types/userPermission'
import { matchPath } from 'react-router-dom'
import { RoutePaths } from '@/navigation/route'
import { UserPermission } from '@/types/userPermission'
import { hasPermission } from '@/navigation/userPermissions'

export enum SiteRoutes {
    HOME = 'home',
    DASHBOARD = 'dashboard',
    REALTIME = 'realtime',
    CONSTRAINTS = 'constraints',
    DOCUMENTS = 'documents',
}

export const siteRoutes: SiteRoutes[] = [
    SiteRoutes.HOME,
    SiteRoutes.DASHBOARD,
    SiteRoutes.REALTIME,
    SiteRoutes.CONSTRAINTS,
    SiteRoutes.DOCUMENTS,
]

export const siteRoutesRequiredPermissions: Record<SiteRoutes, PermissionSection> = {
    [SiteRoutes.HOME]: PermissionSection.DASHBOARD_SITE,
    [SiteRoutes.DASHBOARD]: PermissionSection.DASHBOARD_SITE,
    [SiteRoutes.REALTIME]: PermissionSection.REALTIME,
    [SiteRoutes.CONSTRAINTS]: PermissionSection.CONSTRAINTS,
    [SiteRoutes.DOCUMENTS]: PermissionSection.DOCUMENTS,
}

export function matchSiteRoute(pathname: string): SiteRoutes | undefined {
    return siteRoutes.find((route) => Boolean(matchPath(`${RoutePaths.Sites}/:siteHash/${route}/*`, pathname)))
}

export function getAllowedSiteRoutes(userPermissions: UserPermission[], siteHash: string): SiteRoutes[] {
    const sitePermissions = userPermissions.filter(({ hash }) => hash === siteHash)
    return siteRoutes.filter((href) => hasPermission(sitePermissions, siteRoutesRequiredPermissions[href]))
}
